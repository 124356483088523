import React from 'react'
import { useState, useContext, useEffect, useRef } from "react";
import { Card, Row, Col, Modal, Button, Form, FormGroup, Badge } from "react-bootstrap"
import endpoint from "../../../context/endpoint";
import { ErrorAlert, SuccessAlert } from "../../../data/Toast/toast";
import SignatureCanvas from "react-signature-canvas"
import { ChiefRegistrarsList } from '../../../data/Registrars/ChiefRegistrarsList';
import { Link } from 'react-router-dom';

const ChiefRegistrars = () => {



    return (
        <div>
            <Row>
                <Col sm={12} className="col-12">
                    <Card>
                        <Card.Header>
                            <Card.Title
                                as="h3"
                                className='text-uppercase'
                                style={{ color: "#0A7E51", fontWeight: 900 }}>
                                Chief Registrars
                            </Card.Title>
                            {/* <h3 className="card-title mb-0 text-uppercase" style={{ color: "#0A7E51", fontWeight: 900 }}> Chief Registrars</h3> */}
                            <Col className="text-end">
                                <Link to={`/create-chief-registrar`} className="btn btn-primary btn-outline btn-sm"> <span className="fa fa-plus"></span> Add Registrar</Link>
                            </Col>
                        </Card.Header>
                        <Card.Body>
                            <div className="">
                                <div className="">
                                    <ChiefRegistrarsList />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default ChiefRegistrars