import React from "react";
import { useState, useContext, useEffect, useRef } from "react";
import {
    Card,
    Row,
    Col,
    Modal,
    Button,
    Form,
    Badge,
    Breadcrumb,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import endpoint from "../../context/endpoint";
import { ErrorAlert, SuccessAlert } from "../Toast/toast";
import Loader from "../Loader/loader";
import { Link } from "react-router-dom";
import avatarImg from "../../components/Scn-Components/avatarNoImg3.jpg";
import PassportCard from "../../components/Scn-Components/PassportCard";
import Swal from "sweetalert2";
import "animate.css";
import "./SweetAlert2Styles.css";
import { Context } from "../../context/Context";

export const GraduandListData = () => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [yearOfGraduation, setYearOfGraduation] = useState([]);
    const [details, setDetails] = useState({
        year_of_graduation: "",
    });
    const [uploadStaff, setUploadStaff] = useState({
        fullname: "",
        userId: "",
        passport_photo: "",
    });
    const [batches, setBatches] = useState([]);
    const [batchSearch, setBatchSearch] = useState({
        batch_number: "",
    });
    const [resultByBatch, setResultByBatch] = useState("");
    const [selectedCount, setSelectedCount] = useState("");
    const [openTrash, setTrashBatch] = useState(false);
    const [rmvGraduand, setRmvGraduand] = useState(false);
    const [submitBatchModal, setSubmitBatchModal] = useState(false);
    const [editGraduandModal, setEditGraduandModal] = useState(false);
    const [toEdit, setToEdit] = useState({
        id: "",
        surname: "",
        first_name: "",
        middle_name: "",
        email: "",
        phone: "",
        examination_no: "",
        year_of_graduation: "",
        date_of_birth: "",
        gender: "",
        contact_address: "",
        permanent_home_address: "",
        university_attended: "",
        law_sch_camp_attended: "",
    });
    const [graduandToRmv, setGraduandToRmv] = useState({
        id: "",
        fullname: "",
    });

    //set search details
    const handleBatchChange = (event) => {
        setDetails({ ...details, year_of_graduation: event.target.value });
    };

    const { user } = useContext(Context);

    useEffect(() => {

        getYearOfGraduation();
        getBatch();
    }, []);

    //get year of graduation
    const getYearOfGraduation = async () => {
        try {
            const res = await endpoint.get("/graduand/year-of-graduation");
            setYearOfGraduation(res.data.data);
        } catch (err) {
            console.log(err);
        }
    };

    //get batches
    const getBatch = async () => {
        try {
            const res = await endpoint.get("/import/users/list/batch-number");
            console.log("B1", res.data.data);

            setBatches(res.data.data);
        } catch (err) {
            console.log(err);
        }
    };

    const handleRowSelection = ({ selectedRows }) => {
        setSelectedCount(selectedRows.length);
    };

    const getAllData = async () => {
        setLoading(true);
        await endpoint
            .get(`/graduand/list`)
            .then((res) => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch((err) => console.log(err));
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const gender = [
        { id: 1, value: "Male", label: "Male" },
        { id: 2, value: "Female", label: "Female" },
    ];

    const onClose = () => {
        setOpen(false);
        setUploadStaff({
            fullname: "",
            userId: "",
            passport_photo: "",
        });
        setPreviewImage(null);
    };

    const [previewImage, setPreviewImage] = useState(null);
    const getPreview = (targetFile) => {
        if (targetFile) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(targetFile);
        } else {
            setPreviewImage(null);
        }
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    const handleSearch = async () => {

        setLoading(true);
        setBatchSearch({ ...batchSearch, batch_number: "" });
        setResultByBatch("");
        await endpoint
            .post(`/graduand/search-graduand-by-year-of-graduation`, details)
            .then(({ data }) => {
                setLoading(false);
                setData(data.data);
            })
            .catch((err) => {
                setLoading(false);
                console.log("bad");
                console.log(err);
            });


    };

    const handleSearchByBatch = async () => {
        if (batchSearch.batch_number === "All") {
            getAllData();
        } else {
            setLoading(true);
            setDetails({ ...details, year_of_graduation: "" });
            await endpoint
                .post(`/import/users/search/batch-number`, batchSearch)
                .then(({ data }) => {
                    setLoading(false);
                    setData(data.data);
                    if (
                        data.data[0].submit_status == 0 ||
                        data.data[0].submit_status == null
                    ) {
                        setResultByBatch(data.data[0].batch_number);
                    } else {
                        setResultByBatch("");
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    console.log("bad");
                    console.log(err);
                });
        }
    }

    const savePassport = async (e) => {
        e.preventDefault();
        setLoading(true);
        const myData = new FormData();
        myData.append("userId", uploadStaff.userId);
        myData.append("passport_photo", uploadStaff.passport_photo);
        await endpoint
            .post(`/import/users/upload/photo/${uploadStaff.userId}`, myData)
            .then((res) => {
                setLoading(false);
                setOpen(!open);
                const updatedData = data.map((graduand) => {
                    if (graduand.id == res.data.data.id) {
                        const updated = {
                            ...graduand,
                            passport_url: res.data.data.image,
                        };
                        return updated;
                    }
                    return graduand;
                });
                setData(updatedData);
                setUploadStaff({
                    fullname: "",
                    userId: "",
                    passport_photo: "",
                });
                setPreviewImage(null);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                setOpen(!open);
                setUploadStaff({
                    fullname: "",
                    userId: "",
                    passport_photo: "",
                });
                setPreviewImage(null);
            });
    };

    // const deleteTrash = async (e) => {
    //     e.preventDefault()
    //     setLoading(true)
    //     await endpoint.delete(`/import/users/batch/delete/${resultByBatch}`)
    //         .then((res) => {
    //             console.log(res)
    //             setBatchSearch({ ...batchSearch, batch_number: "" })
    //             setData(data.filter((graduand) => graduand.batch_number !== resultByBatch))
    //             setTrashBatch(!openTrash)
    //             setResultByBatch('')
    //             getBatch()
    //             setLoading(false)
    //         }).catch((err) => {
    //             console.log(err)
    //             setLoading(false)
    //         })
    // }

    const deleteTrash = async (e) => {
        e.preventDefault();

        Swal.fire({
            title: "Are you sure?",
            showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `,
            },
            hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `,
            },
            text: `Confirm you want to trash batch '${resultByBatch}'. This process cannot be reversed.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
            customClass: {
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                try {
                    const res = await endpoint.delete(
                        `/import/users/batch/delete/${resultByBatch}`
                    );
                    console.log(res);
                    setBatchSearch({ ...batchSearch, batch_number: "" });
                    setData(
                        data.filter((graduand) => graduand.batch_number !== resultByBatch)
                    );
                    setResultByBatch("");
                    getBatch();
                    setLoading(false);
                    Swal.fire("Deleted!", "The batch has been deleted.", "success");
                } catch (err) {
                    console.log(err);
                    setLoading(false);
                    Swal.fire(
                        "Error!",
                        "There was an error deleting your batch.",
                        "error"
                    );
                }
            }
        });
    };

    const deleteGraduandOLD = async (e) => {
        e.preventDefault();
        setLoading(true);
        await endpoint
            .delete(`/import/users/delete/${graduandToRmv.id}`)
            .then((res) => {
                console.log("res del", res);
                setData(data.filter((graduand) => graduand.id != res.data.data.id));
                setRmvGraduand(!rmvGraduand);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    const handleDeleteClick = (row) => {
        const graduandToRmv = {
            id: row.row.id,
            fullname: `${row.row.surname} ${row.row.first_name}`,
        };

        deleteGraduand(graduandToRmv);
    };
    const deleteGraduand = async (graduandToRmv) => {
        Swal.fire({
            title: "Are you sure?",
            showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `,
            },
            hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `,
            },
            text: `Confirm you want to delete '${graduandToRmv.fullname}'. This process cannot be reversed.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
            customClass: {
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                try {
                    const res = await endpoint.delete(
                        `/import/users/delete/${graduandToRmv.id}`
                    );
                    console.log(res);
                    setData(data.filter((graduand) => graduand.id != res.data.data.id));
                    // getBatch();
                    setLoading(false);
                    Swal.fire("Deleted!", "Graduand deleted successfully.", "success");
                } catch (err) {
                    console.log(err);
                    setLoading(false);
                    Swal.fire("Error!", "There was an error deleting graduand.", "error");
                }
            }
        });
    };

    const updateGraduand = async (e) => {
        e.preventDefault();
        setLoading(true);
        await endpoint
            .put(`/graduand/edit/${toEdit.id}`, toEdit)
            .then((res) => {
                getAllData();
                setLoading(false);
                SuccessAlert(res.data.message);
                setEditGraduandModal(!editGraduandModal);
                setToEdit("");
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                ErrorAlert(err.response.data.message);
                setEditGraduandModal(!editGraduandModal);
                setToEdit("");
            });
    };

    // const submitBatch = async (e) => {
    //     e.preventDefault()
    //     setLoading(true)
    //     console.log("batch to submit", resultByBatch)
    //     await endpoint.put(`/graduand/submit/${resultByBatch}`)
    //         .then((res) => {
    //             setLoading(false)
    //             setSubmitBatchModal(!submitBatchModal)
    //             SuccessAlert(res.data.message)
    //             setResultByBatch('')
    //             getAllData()
    //         }).catch((err) => {
    //             console.log(err)
    //             setLoading(false)
    //             setResultByBatch('')
    //             setSubmitBatchModal(!submitBatchModal)
    //             ErrorAlert(err.response.data.message)
    //         })
    // }

    const submitBatch = async (e) => {
        e.preventDefault();

        Swal.fire({
            title: "Are you sure?",
            showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `,
            },
            hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `,
            },
            text: `Confirm you want to submit batch '${resultByBatch}'. This process cannot be reversed.`,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes, submit it!",
            cancelButtonText: "No, keep it",
            customClass: {
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                try {
                    const res = await endpoint.put(`/graduand/submit/${resultByBatch}`);
                    console.log(res);
                    // setSubmitBatchModal(!submitBatchModal)
                    setResultByBatch("");
                    getAllData();
                    setLoading(false);
                    Swal.fire("Submitted!", "The batch has been submitted.", "success");
                } catch (err) {
                    console.log(err);
                    setLoading(false);
                    Swal.fire(
                        "Error!",
                        "There was an error submitting your batch.",
                        "error"
                    );
                }
            }
        });
    };

    const columns = [
        {
            name: "S/N",

            cell: (row, index) => index + 1 + (page - 1) * perPage,
            width: "70px",
        },
        {
            name: "Batch",
            selector: (row) => [row.batch_number],
            sortable: true,
            // width: "100px",
            cell: (row) => <h6 className="fs-12 fw-bold">{row.batch_number}</h6>,
        },
        {
            name: "Photo",
            selector: (row) => [row.passport_url],
            sortable: true,
            width: "100px",
            cell: (row) => (
                <div className="">
                    {row.passport_url !== null && (
                        <>
                            <img
                                className="avatar avatar-sm brround cover-image mb-1 ms-0"
                                crossOrigin="anonymous"
                                src={process.env.REACT_APP_UPLOAD_URL + row.passport_url}
                                alt=""
                                style={{ height: `60px`, width: `60px` }}
                                onClick={() => {
                                    setOpen(!open);
                                    setUploadStaff({
                                        ...uploadStaff,
                                        userId: row.id,
                                        fullname:
                                            row.surname.toUpperCase() +
                                            " " +
                                            row.first_name.toUpperCase() +
                                            " " +
                                            row.middle_name.toUpperCase(),
                                        passport_photo: row.passport_url,
                                    });
                                }}
                            />
                            <br></br>
                            <div
                                className="text-center"
                                onClick={() => {
                                    setOpen(!open);
                                    setUploadStaff({
                                        ...uploadStaff,
                                        userId: row.id,
                                        fullname:
                                            row.surname.toUpperCase() +
                                            " " +
                                            row.first_name.toUpperCase() +
                                            " " +
                                            row.middle_name.toUpperCase(),
                                        passport_photo: row.passport_url,
                                    });
                                }}>
                                <span className="fa fa-camera"></span>
                            </div>
                        </>
                    )}
                    {row.passport_url == null && (
                        <>
                            <img
                                className="avatar avatar-sm brround cover-image mb-1 ms-0"
                                crossOrigin="anonymous"
                                src={avatarImg}
                                alt=""
                                style={{ height: `60px`, width: `60px` }}
                                onClick={() => {
                                    setOpen(!open);
                                    setUploadStaff({
                                        ...uploadStaff,
                                        userId: row.id,
                                        fullname:
                                            row.surname.toUpperCase() +
                                            " " +
                                            row.first_name.toUpperCase() +
                                            " " +
                                            row.middle_name.toUpperCase(),
                                        passport_photo: row.passport_url,
                                    });
                                }}
                            />
                            <br></br>
                            <div
                                className="text-center"
                                onClick={() => {
                                    setOpen(!open);
                                    setUploadStaff({
                                        ...uploadStaff,
                                        userId: row.id,
                                        fullname:
                                            row.surname.toUpperCase() +
                                            " " +
                                            row.first_name.toUpperCase() +
                                            " " +
                                            row.middle_name.toUpperCase(),
                                        passport_photo: row.passport_url,
                                    });
                                }}>
                                <span className="fa fa-camera"></span>
                            </div>
                        </>
                    )}
                </div>
            ),
        },
        {
            name: "Full Name",
            selector: (row) => [row.surname],
            sortable: true,
            width: "230px",
            cell: (row) => (
                <h6 className="fs-12 fw-bold">
                    {" "}
                    {row.surname.toUpperCase() +
                        " " +
                        row.first_name.toUpperCase() +
                        " " +
                        row.middle_name.toUpperCase()}
                </h6>
            ),
        },
        {
            name: "Gender",
            selector: (row) => [row.gender],
            sortable: true,
            // width: "100px",
            cell: (row) => <h6 className="fs-12 fw-bold">{row.gender}</h6>,
        },
        {
            name: "Email",
            selector: (row) => [row.email],
            sortable: true,
            width: "250px",
            cell: (row) => <h6 className="fs-12 fw-bold">{row.email}</h6>,
        },
        {
            name: "Phone No.",
            selector: (row) => [row.phone],
            sortable: true,
            width: "150px",
            cell: (row) => <h6 className="fs-12 fw-bold">{row.phone}</h6>,
        },
        {
            name: "Date of Birth",
            selector: (row) => [row.date_of_birth],
            sortable: true,
            width: "150px",
            cell: (row) => (
                <h6 className="fs-12 fw-bold">{formatDate(row.date_of_birth)}</h6>
            ),
        },
        {
            name: <div>Exam No.</div>,
            selector: (row) => [row.examination_no],
            sortable: true,
            width: "110px",
            cell: (row) => (
                <h6 className="fs-12 fw-bold">{row.examination_no.toUpperCase()}</h6>
            ),
        },
        {
            name: <div>Year of Graduation</div>,
            selector: (row) => [row.year_of_graduation],
            sortable: true,
            width: "130px",
            cell: (row) => (
                <h6 className="fs-12 fw-bold">
                    {row.year_of_graduation.toUpperCase()}
                </h6>
            ),
        },
        {
            name: <div>Law School Campus</div>,
            selector: (row) => [row.law_sch_camp_attended],
            sortable: true,
            width: "130px",
            cell: (row) => (
                <h6 className="fs-12 fw-bold">
                    {row.law_sch_camp_attended.toUpperCase()}
                </h6>
            ),
        },
        {
            name: <div>University Attended</div>,
            selector: (row) => [row.university_attended],
            sortable: true,
            width: "130px",
            cell: (row) => (
                <h6 className="fs-12 fw-bold">
                    {row.university_attended.toUpperCase()}
                </h6>
            ),
        },
        {
            name: <div>Contact Address</div>,
            selector: (row) => [row.contact_address],
            sortable: true,
            width: "130px",
            cell: (row) => (
                <h6 className="fs-12 fw-bold">{row.contact_address.toUpperCase()}</h6>
            ),
        },
        // {
        //   name: <div>Status</div>,
        //   selector: (row) => [row.payment_status],
        //   sortable: true,
        //   width: "120px",
        //   cell: (row) => (
        //     <h6 className="fs-12 fw-bold">{(row.payment_status == 0 || row.payment_status == null) ? `Not Paid` : `Paid`}</h6>
        //   ),
        // },
        {
            name: <div>Status</div>,
            selector: (row) => [row.submit_status],
            sortable: true,
            // width: "120px",
            cell: (row) => (
                <h6 className="fs-12 fw-bold">
                    {row.submit_status == 0 || row.submit_status == null
                        ? `Pending`
                        : `Submitted`}
                </h6>
            ),
        },
        {
            name: "Action",
            width: "140px",
            cell: (row) => (
                <div className="fs-12 fw-bold mx-1 ">
                    <td>
                        {
                            // row.submit_status == 1 ||
                            (row.Enrollment?.status_id < 3 || row.submit_status == 1) && (
                                <>
                                    <button
                                        className="btn btn-sm btn-warning m-1"
                                        variant="warning"
                                        title="Edit"
                                        size="sm"
                                        onClick={() => {
                                            setToEdit(row);
                                            setEditGraduandModal(!editGraduandModal);
                                        }}>
                                        <span className="fa fa-edit"></span>
                                    </button>
                                </>
                            )
                        }
                        {(row.submit_status == "" || row.submit_status == null) && (
                            <button
                                className="btn btn-sm btn-danger m-1"
                                variant="danger"
                                title="Delete"
                                size="sm"
                                onClick={() => {
                                    handleDeleteClick({ row });
                                }}>
                                <span className="fa fa-trash"></span>
                            </button>
                        )}
                    </td>
                </div>
            ),
        },
    ];

    const tableDatas = {
        columns,
        data,
    };

    return (
        <>
            <div>
                <div
                    className="page-header"
                    style={{ background: "none" }}>
                    <div>
                        <h1 className="page-title">GRADUAND LIST </h1>
                        <Breadcrumb className="breadcrumb">
                            <Breadcrumb.Item
                                className="breadcrumb-item active"
                                href="#">
                                Home
                            </Breadcrumb.Item>

                            <Breadcrumb.Item
                                className="breadcrumb-item active"
                                aria-current="page">
                                {user.user.fullname}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <div className="ms-auto pageheader-btn">
                        {resultByBatch && (
                            <Link
                                to={"#"}
                                // type="button"
                                className="btn btn-primary btn-icon text-white m-2"
                                onClick={submitBatch}>
                                <span className="fa fa-save"></span> Submit Batch{" "}
                                {resultByBatch}
                            </Link>
                        )}

                        {resultByBatch && (
                            <Link
                                to={"#"}
                                // type="button"
                                className="btn btn-danger btn-icon text-white m-2"
                                onClick={deleteTrash}>
                                <span className="fa fa-trash"></span> Trash Batch{" "}
                                {resultByBatch}
                            </Link>
                        )}

                        <Link
                            to={`/graduants-upload`}
                            className="btn btn-primary btn-icon text-white m-2">
                            {" "}
                            <span className="fa fa-plus"></span> Upload New Batch
                        </Link>
                    </div>
                </div>

                <Card>
                    <Card.Header>
                        <Col className="text-beginning">
                            <Card.Title
                                as="h3"
                                className="text-uppercase"
                                style={{ color: "#0A7E51", fontWeight: 900 }}>
                                Search Graduand Records
                            </Card.Title>
                        </Col>
                    </Card.Header>

                    <Card.Body>
                        <Row>
                            <Col
                                sm={6}
                                className="col-12">
                                <Row className="align-items-center">
                                    <Col
                                        sm={8}
                                        className="col-8">
                                        <Form.Group className="mb-0">
                                            <Form.Label>Search by Year of Graduation</Form.Label>
                                            <Form.Select
                                                className="form-control form-select-m"
                                                value={details.year_of_graduation}
                                                onChange={handleBatchChange}>
                                                <option value="">Select Session...</option>
                                                {yearOfGraduation.map((year, index) => (
                                                    <option
                                                        key={index + 1}
                                                        value={year.year_of_graduation}>
                                                        {year.year_of_graduation}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col
                                        sm={4}
                                        className="col-4">
                                        <Form.Group className="mb-0">
                                            <Form.Label className="invisible">
                                                Search Button
                                            </Form.Label>
                                            <Button
                                                variant="primary"
                                                onClick={handleSearch}
                                                className="form-control btn btn-">
                                                {" "}
                                                <span className="fa fa-search"></span> Search
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                sm={6}
                                className="col-12">
                                <Row className="align-items-center">
                                    <Col
                                        sm={8}
                                        className="col-8">
                                        <Form.Group className="mb-0">
                                            <Form.Label>Search by Batch Number</Form.Label>
                                            <Form.Select
                                                className="form-control form-select-md"
                                                value={batchSearch.batch_number}
                                                onChange={(e) =>
                                                    setBatchSearch({
                                                        ...batchSearch,
                                                        batch_number: e.target.value,
                                                    })
                                                }>
                                                <option value="">Select Batch...</option>
                                                <option value="All">All Batch</option>

                                                {batches.map((batch, index) => (
                                                    <>
                                                        <option
                                                            key={index + 1}
                                                            value={batch.batch_number}>
                                                            {`${batch.batch_number} - (${formatDate(
                                                                batch.createdAt
                                                            )})`}
                                                            {`-Total-(${batch.count})`}
                                                        </option>
                                                    </>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                    <Col
                                        sm={4}
                                        className="col-4">
                                        <Form.Group className="mb-0">
                                            <Form.Label className="invisible">
                                                Search Button
                                            </Form.Label>
                                            <Button
                                                variant="primary"
                                                onClick={handleSearchByBatch}
                                                className="form-control">
                                                {" "}
                                                <span className="fa fa-search"></span> Search
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <br />
                {/* start second card  */}

                <Card>
                    <Card.Header>
                        <Col className="text-beginning">
                            <Card.Title
                                as="h3"
                                style={{ color: "#0A7E51", fontWeight: 900 }}>
                                {resultByBatch
                                    ? ` Batch Number ${resultByBatch && resultByBatch}`
                                    : " Uploaded List of Graduands"}
                            </Card.Title>
                        </Col>
                        {/* <Col sm={3} className="text-end">
                            <Link
                                to={`/graduants-upload`}
                                className="form-control btn btn-primary btn- m-1">
                                {" "}
                                <span className="fa fa-plus"></span> Upload new batch
                            </Link>
                        </Col> */}
                    </Card.Header>

                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Card.Body>
                            {/* <h3 className="text-center mt-5">
                                <span className='fa fa-list'></span>
                               
                                Uploaded List of Graduands {resultByBatch && resultByBatch}{" "}
                            </h3> */}

                            {/* <Row>
                                <Col lg={3} sm={6} md={6} className="col-md-6 col-sm-6">
                                    {resultByBatch && (
                                        <button
                                            className="form-control btn btn-danger pd-x-25 m-1"
                                            onClick={deleteTrash}>
                                            <span className="fa fa-trash"></span> Trash Batch{" "}
                                            {resultByBatch}
                                        </button>
                                    )}
                                </Col>
                                <Col lg={3} sm={6} md={6} className="col-md-6 col-sm-6">
                                    {resultByBatch && (
                                        <button
                                            className="form-control btn btn-primary pd-x-25 m-1"
                                            onClick={submitBatch}>
                                            <span className="fa fa-save"></span> Submit Batch{" "}
                                            {resultByBatch}
                                        </button>
                                    )}

                                </Col>
                            </Row> */}
                            <Row className="row">
                                <Col
                                    md={12}
                                    className="col-md-12">
                                    <DataTableExtensions {...tableDatas}>
                                        <DataTable
                                            //  fixedHeader
                                            columns={columns}
                                            data={data}
                                            // selectableRows={resultByBatch}
                                            // selectableRows
                                            // onSelectedRowsChange={handleRowSelection}
                                            // customStyles={customStyles}
                                            persistTableHead
                                            defaultSortField="id"
                                            defaultSortAsc={false}
                                            striped={true}
                                            center={true}
                                            pagination
                                            // onChangePage={handlePageChange}
                                            // onChangeRowsPerPage={handlePerRowsChange}
                                            paginationRowsPerPageOptions={[
                                                10, 15, 20, 25, 30, 50, 100,
                                            ]}
                                            // paginationPerPage={perPage}
                                            highlightOnHover
                                        />
                                    </DataTableExtensions>

                                    <Modal show={open}>
                                        <Modal.Body className="text-center p-4">
                                            <Button
                                                onClick={onClose}
                                                className="btn-close"
                                                variant="">
                                                x
                                            </Button>
                                            {/* <i className="fe fe-check-circle fs-100 text-success lh-1 mb-4 d-inline-block"></i>
                      <h4 className="text-success mb-4">Alert!</h4> */}
                                            <p className="mb-4 mx-4 mt-4">
                                                Upload Passport Photo for <br></br>{" "}
                                                <span className="text-success">
                                                    {uploadStaff.fullname}
                                                </span>
                                            </p>
                                            <p>
                                                <div className="mt-2">
                                                    <label>Select Photo.</label>
                                                    <input
                                                        type="file"
                                                        name="passport_url"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            getPreview(e.target.files[0]);
                                                            setUploadStaff({
                                                                ...uploadStaff,
                                                                passport_photo: e.target.files[0],
                                                            });
                                                        }}
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    {previewImage ? (
                                                        <PassportCard image={previewImage} />
                                                    ) : uploadStaff.passport_photo !== null ? (
                                                        <PassportCard
                                                            image={
                                                                process.env.REACT_APP_UPLOAD_URL +
                                                                "/" +
                                                                uploadStaff.passport_photo
                                                            }
                                                        />
                                                    ) : (
                                                        " "
                                                    )}
                                                </div>
                                            </p>
                                            <button
                                                className="btn btn-danger pd-x-25 m-1"
                                                onClick={onClose}>
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-success pd-x-25"
                                                onClick={(e) => savePassport(e)}>
                                                Save
                                            </button>
                                        </Modal.Body>
                                    </Modal>

                                    {/* Edit graduand */}
                                    <Modal
                                        show={editGraduandModal}
                                        size="lg">
                                        <Modal.Header>
                                            <Col className="text-beginning">
                                                <Card.Title as="h3"> Edit Graduand Data</Card.Title>
                                            </Col>
                                            <Col
                                                sm={3}
                                                className="text-end">
                                                <Button
                                                    onClick={() =>
                                                        setEditGraduandModal(!editGraduandModal)
                                                    }
                                                    className="btn-close"
                                                    variant="">
                                                    x
                                                </Button>
                                            </Col>
                                        </Modal.Header>

                                        <Modal.Body className="p-4">
                                            {toEdit && (
                                                <>
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>Surname</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={toEdit.surname}
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            surname: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>First Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={toEdit.first_name}
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            first_name: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>Middle Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={toEdit.middle_name}
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            middle_name: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>Email</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={toEdit.email}
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            email: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>Gender</label>
                                                                <select
                                                                    className="form-select"
                                                                    onChange={(e) => {
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            gender: e.target.value,
                                                                        });
                                                                    }}
                                                                    value={toEdit.gender}>
                                                                    <option value="">Select Gender...</option>
                                                                    {gender.map((list) => (
                                                                        <option
                                                                            key={list.id}
                                                                            value={list.value}>
                                                                            {list.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>Phone Number</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={toEdit.phone}
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            phone: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>Exam No</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={toEdit.examination_no}
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            examination_no: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>Date of Birth</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={toEdit.date_of_birth}
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            date_of_birth: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>Year of Graduation</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={toEdit.year_of_graduation}
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            year_of_graduation: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <label>Law School Campus</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={toEdit.law_sch_camp_attended}
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            law_sch_camp_attended: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>

                                                            <div className="col-md-4">
                                                                <label>University Attended</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={toEdit.university_attended}
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            university_attended: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            </div>

                                                            {/* <div className="col-md-4">
                                                                <label>Contact Address</label>

                                                                <textarea className="form-control" onChange={(e) => setToEdit({ ...toEdit, contact_address: e.target.value, })}>{toEdit.contact_address}</textarea>
                                                               
                                                            </div> */}
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label>Permanent Address</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            permanent_home_address: e.target.value,
                                                                        })
                                                                    }>
                                                                    {toEdit.permanent_home_address}
                                                                </textarea>
                                                                {/* <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={toEdit.permanent_home_address}
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            permanent_home_address: e.target.value,
                                                                        })
                                                                    }
                                                                /> */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <label>Contact Address</label>

                                                                <textarea
                                                                    className="form-control"
                                                                    onChange={(e) =>
                                                                        setToEdit({
                                                                            ...toEdit,
                                                                            contact_address: e.target.value,
                                                                        })
                                                                    }>
                                                                    {toEdit.contact_address}
                                                                </textarea>
                                                                {/* <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={toEdit.contact_address}
                                                                    onChange={(e) => setToEdit({ ...toEdit, contact_address: e.target.value, })}
                                                                /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </Modal.Body>

                                        <Modal.Footer>
                                            <Button
                                                variant="danger"
                                                className="me-1"
                                                onClick={() =>
                                                    setEditGraduandModal(!editGraduandModal)
                                                }>
                                                Close
                                            </Button>
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                className="me-1"
                                                onClick={(e) => updateGraduand(e)}>
                                                <span className="fe fe-arrow-right"></span>
                                                Save
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    {/* End Edit graduand */}

                                    {/* submit batch modal */}
                                    {/* <Modal show={submitBatchModal}>
                                        <Modal.Body className="text-center p-4">
                                            <Button
                                                onClick={onClose}
                                                className="btn-close"
                                                variant="">
                                                x
                                            </Button>
                                            <h4 className="text-success mb-4">Alert!</h4>
                                            <p className="mb-4 mx-4 mt-4">
                                                Confirm You want to submit batch <br></br>{" "}
                                                <span className="text-success">{resultByBatch}</span>
                                            </p>

                                            <button
                                                className="btn btn-danger pd-x-25 m-1"
                                                onClick={() => setSubmitBatchModal(!submitBatchModal)}>
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-success pd-x-25"
                                                onClick={(e) => submitBatch(e)}>
                                                Continue
                                            </button>
                                        </Modal.Body>
                                    </Modal> */}
                                    {/* End submit batch modal */}

                                    {/* remove single graduand modal */}
                                    {/* <Modal show={rmvGraduand}>
                                        <Modal.Body className="text-center p-4">
                                            <Button
                                                onClick={onClose}
                                                className="btn-close"
                                                variant="">
                                                x
                                            </Button>
                                            <h4 className="text-danger mb-4">Alert!</h4>
                                            <p className="mb-4 mx-4 mt-4">
                                                Confirm You want to remove <br></br>{" "}
                                                <span className="text-success">
                                                    {graduandToRmv.fullname}
                                                </span>
                                            </p>

                                            <button
                                                className="btn btn-danger pd-x-25 m-1"
                                                onClick={() => setRmvGraduand(!rmvGraduand)}>
                                                Cancel
                                            </button>
                                            <button
                                                className="btn btn-success pd-x-25"
                                                onClick={(e) => deleteGraduand(e)}>
                                                Continue
                                            </button>
                                        </Modal.Body>
                                    </Modal> */}
                                    {/*End remove single graduand modal */}
                                </Col>
                            </Row>

                            <Row>
                                <Col
                                    lg={3}
                                    sm={6}
                                    md={6}
                                    className="col-md-6 col-sm-6">
                                    {resultByBatch && (
                                        <button
                                            className="form-control btn btn-danger pd-x-25 m-1"
                                            onClick={deleteTrash}>
                                            <span className="fa fa-trash"></span> Trash Batch{" "}
                                            {resultByBatch}
                                        </button>
                                    )}
                                </Col>
                                <Col
                                    lg={3}
                                    sm={6}
                                    md={6}
                                    className="col-md-6 col-sm-6">
                                    {resultByBatch && (
                                        <button
                                            className="form-control btn btn-primary pd-x-25 m-1"
                                            onClick={submitBatch}>
                                            <span className="fa fa-save"></span> Submit Batch{" "}
                                            {resultByBatch}
                                        </button>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    )}
                </Card>
            </div>
        </>
    );
};
