import React from "react";
import { useState, useContext, useEffect, useRef } from "react";
import { Card, Row, Col, Modal, Button, Form, Badge } from "react-bootstrap"
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import endpoint from "../../context/endpoint";
import { ErrorAlert, SuccessAlert } from "../Toast/toast";
import Loader from "../Loader/loader";
import { Link } from "react-router-dom";

export const ChiefRegistrarsList = () => {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        getAllData();
    }, []);

    const getAllData = async () => {
        setLoading(true);
        await endpoint.get(`/registrar/list`)
            .then(({ data }) => {
                setData(data.data)
                setLoading(false);
            })
            .catch((err) => console.log(err))
    }

    const handlePageChange = page => {
        setPage(page);
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);

    }

    const onClose = () => {
        setOpen(false);
    }

    const columns = [
        {
            name: "S/N",

            cell: (row, index) => (index + 1) + ((page - 1) * perPage),
            width: "70px"
        },
        {
            name: "Fullname",
            selector: (row) => [row.surname],
            sortable: true,
            //   width: "400px",
            cell: (row) => (
                <h6 className="fs-12 fw-bold"> {(row.surname).toUpperCase() + ' ' + (row.first_name).toUpperCase() + " " + (row.middle_name).toUpperCase()}</h6>
            ),
        },
        {
            name: "Email",
            selector: (row) => [row.email],
            sortable: true,
            width: "400px",
            cell: (row) => (
                <h6 className="fs-12 fw-bold"> {row.email}</h6>
            ),
        },
        {
            name: "Signature",
            selector: (row) => [row.email],
            sortable: true,
            width: "200px",
            cell: (row) => (
                <>
                    {/* {process.env.REACT_APP_UPLOAD_URL + row.passport_url} */}
                    <img
                        style={{
                            width: "60px",
                            // height: "60px",
                        }}
                        src={process.env.REACT_APP_UPLOAD_URL + row.signature}
                        className="avatar avatar-sm cover-image mb-1 ms-0"
                        // crossOrigin="anonymous"
                        alt="passport"
                    />
                </>
            ),
        },

        {
            name: "Action",
            //   width: "200px",
            cell: (row) => (
                <div className="fs-12 fw-bold mx-1 ">
                    <td>
                        <Link to={`/create-chief-registrar/${row.id}`}>
                            <button className="btn btn-sm btn-primary m-1" variant="warning" title="Action" size="sm">
                                <span className="fa fa-edit"></span> Edit
                            </button>
                        </Link>

                    </td>
                </div>
            )
        },


    ];

    const tableDatas = {
        columns,
        data,
    };

    return (
        <>
            {isLoading ?
                <Loader /> :

                <div>
                    <Row className="row">
                        <Col md={12} className="col-md-12">
                            <DataTableExtensions {...tableDatas}>
                                <DataTable
                                    //  fixedHeader
                                    columns={columns}
                                    data={data}
                                    // customStyles={customStyles}
                                    persistTableHead
                                    defaultSortField="id"
                                    defaultSortAsc={false}
                                    striped={true}
                                    center={true}
                                    pagination
                                    // onChangePage={handlePageChange}
                                    // onChangeRowsPerPage={handlePerRowsChange}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
                                    // paginationPerPage={perPage}
                                    highlightOnHover
                                />
                            </DataTableExtensions>
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}