import React, { useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import endpoint from '../../../context/endpoint'
import { Context } from '../../../context/Context';

export default function PasswordUpdate() {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useContext(Context);
  const userID = user.user.id;

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState({
    password: "",
    new_password: "",
    confirm_password: "",
  });

  const successAlert = () => {
    toast.success("Password Updated Successfully", {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const errorAlert = (message) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (value.new_password !== value.confirm_password) {
      errorAlert("New password and confirm password do not match.");
      setLoading(false);
      return;
    }

    try {
      const response = await endpoint.put(`/user/update/password/${userID}`, value);
      if (response.status === 200) {
        successAlert();
        setLoading(false);
      } else {
        errorAlert("Failed to update password.");
      }
    } catch (error) {
      errorAlert(error.response?.data?.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-img">
      <div className="page">
        <div className="container-login100">
          <div className="wrap-login100 p-5 custom-form-container">
            <Card.Body>
              <form className="login100-form validate-form" onSubmit={handleSubmit}>
                <div className="row-3"></div>
                <span className="login100-form-title">Update Password</span>
                <div className="wrap-input100 validate-input">
                  <label htmlFor="current-password">Current Password</label>
                  <input
                    className="input100"
                    id="current-password"
                    type="password"
                    placeholder="Password"
                    value={value.password}
                    onChange={(e) => setValue({ ...value, password: e.target.value })}
                  />
                  <span className="focus-input100"></span>
                  <span className="symbol-input100">
                    {/* <i className="zmdi zmdi-lock" aria-hidden="true"></i> */}
                  </span>
                </div>
                <div className="wrap-input100 validate-input">
                  <label htmlFor="new-password">New Password</label>
                  <input
                    className="input100"
                    id="new-password"
                    type="password"
                    placeholder="New Password"
                    value={value.new_password}
                    onChange={(e) => setValue({ ...value, new_password: e.target.value })}
                  />
                  <span className="focus-input100"></span>
                  <span className="symbol-input100">
                    {/* <i className="zmdi zmdi-lock" aria-hidden="true"></i> */}
                  </span>
                </div>
                <div className="wrap-input100 validate-input">
                  <label htmlFor="confirm-password">Confirm Password</label>
                  <input
                    className="input100"
                    id="confirm-password"
                    type="password"
                    placeholder="Confirm password"
                    value={value.confirm_password}
                    onChange={(e) => setValue({ ...value, confirm_password: e.target.value })}
                  />
                  {/* <span className="focus-input100"></span> */}
                  <span className="symbol-input100">
                    {/* <i className="zmdi zmdi-lock" aria-hidden="true"></i> */}
                  </span>
                </div>
                <div className="container-login100-form-btn">
                  <button
                    className={
                      loading
                        ? "login100-form-btn btn-primary btn-loading"
                        : "login100-form-btn btn-primary"
                    }
                    disabled={loading}
                    style={{ border: "0", outline: "none" }}>
                    {loading ? "Loading..." : "Update"}
                  </button>
                </div>
              </form>
            </Card.Body>
          </div>
        </div>
      </div>
    </div>
  );
}
