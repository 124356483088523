import React, { useState, useEffect } from "react";
import { Card, Col, Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import endpoint from "../../context/endpoint";
import moment from "moment";
import { ErrorAlert, SuccessAlert } from "../../data/Toast/toast";
import { Link, useParams, useNavigate } from "react-router-dom";
import PassportCard from "../../components/Scn-Components/PassportCard";

const GetSingleEnrollment = () => {
  const [data, setData] = useState({});
  const params = useParams();
  const navigate = useNavigate();
  const [deleteOpen, setDeleteOpen] = useState(false);

  useEffect(() => {
    getSingleEnrollment();
  }, []);

  const onDelete = () => {
    setDeleteOpen(true);
  };

  const getSingleEnrollment = async () => {
    console.log("id", params.id);
    try {
      const res = await endpoint.get(`/enrollment/show/${params.id}`);
      console.log("single enrollment", res.data.data);
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (e) => {
    // console.log("title2 to delete", idToDelete)
    e.preventDefault();
    await endpoint
      .delete(`/enrollment/delete/${params.id}`)
      .then((res) => {
        console.log(res.data);
        SuccessAlert(res.data.message);
        setDeleteOpen(false);
        navigate(`${process.env.PUBLIC_URL}/enrolled-list`);
      })
      .catch((err) => {
        ErrorAlert(err.response.data.message);
        console.log(err);
      });
  };

  return (
    <div>
      <Col
        xl={12}
        md={12}>
        <Card className="card border">
          <Card.Body>
            <div className="text-center">
              <PassportCard
                image={process.env.REACT_APP_UPLOAD_URL + data.passport_url}
              />
            </div>
            <div className="mt-5">
              <h4 className="text-center">Profile Information</h4>
              <hr className="my-4" />
              <div className="row m-5">
                <div className="col-md-6 p">
                  <p className="fw-bold">Name:</p>
                  <p className="fw-bold">Gender:</p>
                  <p className="fw-bold">Local Government:</p>
                  <p className="fw-bold">Date of Birth:</p>
                  <p className="fw-bold">Law School Campus Attended:</p>
                  <p className="fw-bold">University Attended:</p>
                  <p className="fw-bold">Year of Admission to Law School:</p>
                  <p className="fw-bold">Year of Call to Bar:</p>
                  <p className="fw-bold">State of Origin:</p>
                  <p className="fw-bold">Permanent Home Address:</p>
                  <p className="fw-bold">Phone Number:</p>
                  <p className="fw-bold">Email Address:</p>
                  <p className="fw-bold">Nationality:</p>
                  <p className="fw-bold">Status:</p>
                </div>
                <div className="col-md-6">
                  <p>
                    {data.Title ? data.Title.name : ""}{" "}
                    {`${data.surname} ${data.first_name}
					${data.middle_name}`}
                  </p>
                  <p>{data.gender}</p>
                  <p>{data.Lga ? data.Lga.name : ""}</p>
                  <p>{data.date_of_birth}</p>
                  <p>{data.law_sch_camp_attended}</p>
                  <p>{data.university_attended}</p>
                  <p>{data.year_of_admission_law_sch}</p>
                  <p>{data.year_of_call_to_bar}</p>
                  <p>{data.State ? data.State.name : ""}</p>
                  <p>{data.permanent_home_address}</p>
                  <p>{data.phone}</p>
                  <p>{data.email}</p>
                  <p>{data.Country ? data.Country.name : ""}</p>
                  <span
                    className="badge text-dark"
                    style={{
                      backgroundColor: data.Status ? data.Status.color : "",
                    }}>
                    {data.Status ? data.Status.name : ""}
                  </span>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default GetSingleEnrollment;
