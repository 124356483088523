import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import endpoint from "../../context/endpoint";
import avatarNoImg from "../Scn-Components/avatarNoImg3.jpg";
import './enrollment_confirmation_print.css'

const EnrollmentConfirmationPrint = () => {
    const [loading, setLoading] = useState(false);
    const [enroll, setEnroll] = useState({});
    const [previewImage, setPreviewImage] = useState(null);
    const params = useParams();
    useEffect(() => {
        getUser();
      }, []);
    
      let myformattedDate='';

      function formatDatePlacement(dateString) {
		const date = new Date(dateString);
		const day = date.getDate();
		const monthIndex = date.getMonth();
		const year = date.getFullYear();

		const monthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

        myformattedDate = `${day} ${monthNames[monthIndex]} ${year}`;
		return myformattedDate;
	}
    

    const getTodaysDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${day}-${month}-${year}`;
    };

      const getUser = async () => {
        setLoading(true);
        await endpoint
          .get(`/enrollment/show/graduand/${params.id}`)
          .then((res) => {
            setLoading(false);
            setEnroll(res.data.data);
            console.log("res data", res.data.data)
            if (
              res.data.data.passport_url == null ||
              res.data.data.passport_url == ""
            ) {
              setPreviewImage(avatarNoImg);
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      };

  return (<>
    {enroll &&
        <div id="print">
            <div className="main-container-print-confirm mt-5 mb-10">
            <div className="header-print-confirm">
                <div className="img-col">
                <img className="img-main" src="/img/scnlogo.png" alt="Logo" />
                </div>
                <div className="inside-head1">
                <div className="inside-head2">SUPREME COURT OF NIGERIA</div>
                <div>CONFIRMATION OF ENROLLMENT</div>
                </div>
            </div>
            <div className="inner-container-print-confirm"> 
                <div className="first-col">
                <div className="rowA">
                    <span>
                    This is to certify that  
                    <input type="text" size={40} value={enroll.surname+" "+(enroll.first_name ? enroll.first_name: " ")+" "+(enroll.middle_name ? enroll.middle_name : " ")}/>
                    has successfully  completed the enrollment process and is hereby verified.
                    </span>
                </div>
                <div className="rowA">
                    <span className>
                    Date of Birth: 
                    <input type="text" size={40} value={(enroll.Profile?.formatted_date_of_birth)}/>
                    </span>
                </div>
                <div className="rowA">
                    <span className>
                    Enrollment Number:
                    <input type="text" size={30} value={enroll.Profile?.enrollment_number}/>
                    </span>
                </div>
                <div className="rowA">
                    <span className>
                    Date of Placement:
                    <input type="text" size={40} value={(enroll.Profile?.formatted_date_of_placement)}/>
                    </span>
                </div>          
                </div>
                <div className="second-col">
                <img src={process.env.REACT_APP_UPLOAD_URL+enroll.passport_url} crossOrigin="anonymous" style={{height: 194, width: 200}} alt="No Image..."/>
                </div>
            </div>
            <div className="foot">
                {/* <span className>
                Date Printed:
                <input type="text" size={20} value={getTodaysDate()}/>
                </span>  */}
                <div className="sign-col">
                <span className>
                    <div className="row justify-content-center">
                        <img src={process.env.REACT_APP_UPLOAD_URL+enroll.Profile?.Registrar?.signature} crossOrigin="anonymous" style={{height: 50, width: 150}} alt="No Image..."/>
                    </div>
                    <strong><input type="text" className="text-center" size={40} value={enroll.Profile?.Registrar?.surname+" "+(enroll.Profile?.Registrar?.first_name)+" "+(enroll.Profile?.Registrar?.middle_name)}/></strong>
                </span>
                <span className="">
                    
                    {/* <input type="text" size={40} /> */}
                    
                </span>
                <div className="chief">
                    <div style={{margin: '0 auto'}}>Chief Registrar,</div>
                    <div style={{margin: '0 auto'}}>Supreme Court of Nigeria</div>
                </div> 
                </div>       
            </div>
            </div>

            <div className='row'>
                <div className='text-center'>
                    <button onClick={() => window.print()} className="btn btn-primary m-2 hideBtn"> <span className="fa fa-print"></span> Print</button>
                </div>
            </div>
        </div>
    }
</>)
}

export default EnrollmentConfirmationPrint