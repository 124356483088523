import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Table, Pagination } from "react-bootstrap";
import Loader from "../Loader/loader";
import { Context } from "../../context/Context";
import "./assign.css";

export const CreateUniqueNumber = ({ datas }) => {
    const { user } = useContext(Context);
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        setData(datas);
    }, [datas]);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    };

    const handlePerPageChange = (e) => {
        setPerPage(Number(e.target.value));
        setPage(1); // Reset to page 1 when perPage changes
    };

    // Calculate start and end index for current page
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    const paginatedData = data.slice(startIndex, endIndex);

    const totalPages = Math.ceil(data.length / perPage);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    {/* <Row className="d-flex justify-content-center">
                        <Col md={12}>
                            <Card>
                                <Card.Body> */}
                    <Row className="d-flex justify-content-center">
                        <Col md={10}>
                            <Table striped bordered hover responsive className="custom-table">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th style={{ width: "400px" }}>Unique Code</th> {/* Set width here */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{startIndex + index + 1}</td>
                                            <td>{item.unique_number}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>


                            {/* Pagination Controls */}
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <label>Rows per page: </label>
                                    <select value={perPage} onChange={handlePerPageChange}>
                                        <option value={10}>10</option>
                                        <option value={20}>20</option>
                                        <option value={50}>50</option>
                                    </select>
                                </div>
                                <Pagination>
                                    <Pagination.First
                                        onClick={() => handlePageChange(1)}
                                        disabled={page === 1}
                                    />
                                    <Pagination.Prev
                                        onClick={() => handlePageChange(page - 1)}
                                        disabled={page === 1}
                                    />
                                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                                        (pageNumber) => (
                                            <Pagination.Item
                                                key={pageNumber}
                                                active={pageNumber === page}
                                                onClick={() => handlePageChange(pageNumber)}
                                            >
                                                {pageNumber}
                                            </Pagination.Item>
                                        )
                                    )}
                                    <Pagination.Next
                                        onClick={() => handlePageChange(page + 1)}
                                        disabled={page === totalPages}
                                    />
                                    <Pagination.Last
                                        onClick={() => handlePageChange(totalPages)}
                                        disabled={page === totalPages}
                                    />
                                </Pagination>
                            </div>
                        </Col>
                    </Row>
                    {/* </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}
                </div>
            )}
        </>
    );
};
