import React, {useEffect} from 'react'
import Img1 from './img/scnhomepage.jpg';

const Instructions = () => {

    useEffect(() => {
        const loadWhatsAppWidget = () => {
           // Replace with your group invite link
    
          const options = {
            whatsapp: "+2348134670604", // WhatsApp number
            call_to_action: "Chat us now", // Call to action
            position: "left", // Position may be 'right' or 'left'
            pre_filled_message: `Chat is now`, // WhatsApp pre-filled message
          };
    
          const proto = document.location.protocol;
          const host = "getbutton.io";
          const url = proto + "//static." + host;
    
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.async = true;
          script.src = url + '/widget-send-button/js/init.js';
    
          script.onload = () => {
            window.WhWidgetSendButton.init(host, proto, options);
          };
    
          document.body.appendChild(script);
        };

        const loadTawkToScript = () => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://embed.tawk.to/65de8a8a8d261e1b5f663929/1hnmkqbr5';
            script.charset = 'UTF-8';
            script.setAttribute('crossorigin', '*');
      
            document.body.appendChild(script);
          };
          
        loadTawkToScript();
        loadWhatsAppWidget();
    
        // Cleanup function
        return () => {
          // You may want to remove the script when the component unmounts
          // Example: document.body.removeChild(script);
        };
    }, []);

    return (
      <>
        <div
          className="container-fluid page-header py-5 mb-5 wow fadeIn"
          data-wow-delay="0.1s"
          style={{ marginTop: `0px` }}>
          <div className="container text-center py-5">
            <h1 className="display-3 text-white mb-4 animated slideInDown">
              Instructions
            </h1>
          </div>
        </div>

        <div className="container-xxl py-5">
          <div className="container">
            <div className="row g-5 align-items-end">
              <h2 className="mb-3 text-center">Enrollment Process</h2>
              <div
                className="col-lg-6 col-md-12 wow fadeInUp"
                data-wow-delay="0.3s">
                <div className="row g-5">
                  <div className="col-12 col-sm-6 col-lg-12">
                    <div className="border-start ps-4">
                      <i className="fa fa-check fa-1x text-primary mb-5" />
                      <p>
                        <strong>1.</strong> Click{" "}
                        <a href="https://lawyerenrollment.com.ng/enrollment-search">
                          <i style={{ color: "red" }}>here</i>
                        </a>{" "}
                        or <i style={{ color: "red" }}>Proceed to Enroll</i>{" "}
                        button on the Landing Page
                      </p>
                    </div>
                    <div className="border-start ps-4">
                      <i className="fa fa-check fa-1x text-primary mb-5" />
                      <p>
                        <strong>2.</strong> Search for your{" "}
                        <i style={{ color: "red" }}>details</i> using your{" "}
                        <i style={{ color: "red" }}> Examination Number</i> and{" "}
                        <i style={{ color: "red" }}>Date of Birth.</i>
                      </p>
                    </div>
                    <div className="border-start ps-4">
                      <i className="fa fa-check fa-1x text-primary mb-3" />
                      <p>
                        <strong>3.</strong> If your{" "}
                        <i style={{ color: "red" }}>Name</i> is found, a pop-up
                        modal containing your{" "}
                        <i style={{ color: "red" }}>Details</i> will display,
                        including the{" "}
                        <i style={{ color: "red" }}>Payment Amount</i>. Click on{" "}
                        <i style={{ color: "red" }}>PAY</i> to make payment.
                      </p>
                    </div>
                    <div className="border-start ps-4">
                      <i className="fa fa-check fa-1x text-primary mb-3" />
                      <p>
                        <strong>4.</strong> After a successful <i>Payment</i>, a
                        link will be sent to your <i>Email</i>. Check your{" "}
                        <i style={{ color: "red" }}>Mail Inbox</i> or{" "}
                        <i style={{ color: "red" }}>Spam folder</i> and click on
                        the link to <i style={{ color: "red" }}>continue</i>{" "}
                        your Enrollment.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="row g-5">
                  <div className="col-12 col-sm-6 col-lg-12">
                    <div className="border-start ps-4">
                      <i className="fa fa-check fa-1x text-primary mb-3" />
                      <p>
                        <strong>5.</strong> On Enrollment Form, Upload your
                        Passport and signature, it is adviced to crop uploaded
                        signature for easy verification.{" "}
                        <i style={{ color: "red" }}>Note:</i>{" "}
                        <i style={{ color: "red" }}>
                          Accepted file formats are .jpg, .png, .jpeg <br /> and
                          the file size should not exceed 50kb for passport and
                          signature and 100KB for other documents
                        </i>
                      </p>
                    </div>
                    <div className="border-start ps-4">
                      <i className="fa fa-check fa-1x text-primary mb-3" />
                      <p>
                        <strong>6.</strong> Fill in other details accurately and
                        upload your call to bar certificate and qualifying
                        certificate.
                      </p>
                    </div>
                    <div className="border-start ps-4">
                      <i className="fa fa-check fa-1x text-primary mb-3" />
                      <p>
                        <strong>7.</strong> Confirm that all details entered are accurate then 
                        click on the{" "}
                        <i style={{ color: "red" }}>checkbox then click on save</i> button.
                      </p>
                    </div>
                    <div className="border-start ps-4">
                      <i className="fa fa-check fa-1x text-primary mb-3" />
                      <p>
                        <strong>8.</strong> <strong>Note</strong>: When coming
                        for{" "}
                        <i style={{ color: "red" }}>physical verification</i>,
                        please bring a{" "}
                        <i style={{ color: "red" }}>
                          government-issued photo ID Card
                        </i>
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Instructions