import React, { useState, useContext, useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import endpoint from "../../../context/endpoint";
import { Tabs, Tab, Breadcrumb, Card, Row, Col, Table, Form, FormGroup, FormControl, ListGroup } from "react-bootstrap";
import Loader from "../../../data/Loader/loader";

import { Context } from "../../../context/Context";
import { CardActions, CardContent, Collapse, IconButton } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";

import Swal from "sweetalert2";
import "animate.css";
import "../../../data/Graduands/SweetAlert2Styles.css";
import { CForm } from "@coreui/react";
import { useForm } from "react-hook-form";
import defaultProfileImage from "../../../assets/images/users/image-profile.jpg";

export default function MyProfile() {
    const { register, handleSubmit, reset } = useForm();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const { user, dispatch } = useContext(Context);
    console.log("H1", user);
    const [expanded, setExpanded] = React.useState(true);
    const [show, setShow] = React.useState(true);

    const userID = user.user.id;

    const [value, setValue] = useState({
        password: "",
        new_password: "",
        confirm_password: "",
    });

    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        getUser()
    }, [])

    const getUser = async () => {
        setLoading(true)
        await endpoint.get(`/profile/show-auth-profile`)
            .then(({ data }) => {
                // console.log("profile details for update", data)
                setData(data.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }


    const handleSubmitOLD = async (e) => {
        e.preventDefault();
        setLoading(true);

        // if (value.new_password !== value.confirm_password) {
        //     errorAlert("New password and confirm password do not match.");
        //     setLoading(false);
        //     return;
        // }

        try {
            const response = await endpoint.put(`/user/update/password/${userID}`, value);
            if (response.status === 200) {
                // successAlert();
                setLoading(false);
            } else {
                // errorAlert("Failed to update password.");
            }
        } catch (error) {
            // errorAlert(error.response?.data?.message || "An error occurred. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const submitChangePassword = async () => {
        // e.preventDefault();

        Swal.fire({
            title: "Are you sure?",
            showClass: {
                popup: `
                  animate__animated
                  animate__fadeInUp
                  animate__faster
                `,
            },
            hideClass: {
                popup: `
                  animate__animated
                  animate__fadeOutDown
                  animate__faster
                `,
            },
            text: `Confirm you want to change password. This process cannot be reversed.`,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes, submit it!",
            cancelButtonText: "No, keep it",
            customClass: {
                confirmButton: "custom-confirm-button",
                cancelButton: "custom-cancel-button",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);


                const data = new FormData();
                data.append("password", password);
                data.append("new_password", newPassword);
                data.append("confirm_password", confirmPassword);

                console.log('password 2', password);
                console.log('newPassword 4', newPassword);
                console.log('confirmPassword 6', confirmPassword);

                // console.log(data);
                try {
                    const res = await endpoint.put(`/user/update/password/${userID}`, data);
                    console.log(res);
                    getUser();
                    setLoading(false);
                    // Swal.fire("Submitted!", "Password changed Successfully.", "success");
                    Swal.fire("Submitted!", "Password changed Successfully.", "success").then(() => {
                        logout();
                    });
                } catch (err) {
                    console.log(err);
                    setLoading(false);
                    Swal.fire(
                        "Error!",
                        err.response.data.description,
                        "error"
                    );
                }
            }
        });
    };

    const logout = async () => {
        try {
            await endpoint.get(`/auth/logout`)
            dispatch({ type: "LOGOUT" });
            localStorage.removeItem("modules")
            localStorage.removeItem("user")
            window.location.href = '/loginv2';

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <div
                className="page-header"
                style={{ background: "none" }}>
                <div>
                    <h1 className="page-title" style={{ color: '#0A7E51' }}>Profile</h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item
                            className="breadcrumb-item active"
                            href="#">
                            Home
                        </Breadcrumb.Item>

                        <Breadcrumb.Item
                            className="breadcrumb-item active"
                            aria-current="page">
                            {user.user.fullname}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                {user.user.role_id === 2 && (
                    <div className="ms-auto pageheader-btn">

                        <Link
                            to="/change-of-name"
                            className="btn btn-danger btn-icon text-white"
                            style={{ border: 'white' }}
                        >
                            <span>
                                <i className="fe fe-edit"></i>&nbsp;
                            </span>
                            Change Credence
                        </Link>
                    </div>
                )}
            </div>

            <Row>
                <Card className="card ">
                    <CardActions className="card-header text-">
                        {/* <h3 className="card-title ">Employee card</h3> */}
                        <div className="rtlcards ">
                            <ExpandMore
                                expand={expanded}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>
                            <IconButton

                                size="small"
                                edge="start"
                                color="inherit"
                                onClick={() => setShow(false)}
                                aria-label="close"
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </div>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto">
                        <CardContent className="card-body text-center">
                            {/* {data ?
                                <img
                                    className="avatar avatar-xxl brround cover-image cover-image mt-3 mb-3"
                                    src={process.env.REACT_APP_UPLOAD_URL + data.passport_url} alt=""
                                />
                                : 'Passport...'} */}

                            <img
                                src={!data || !data.passport_url ? defaultProfileImage : `${process.env.REACT_APP_UPLOAD_URL}${data.passport_url}`}
                                alt="Passport ..."
                                className="avatar avatar-xxl brround cover-image cover-image mt-3 mb-3"
                            />

                            <div className="mb-3">
                                <span className="h4 mb-0 mt-3" style={{ color: '#0A7E51' }}>{user?.user.fullname}</span>
                                <br />
                                <span className="card-text">{user?.user.role_id === 1 ? "Administrator" : "Lawyer"} </span>
                            </div>

                        </CardContent>
                    </Collapse>
                </Card>
            </Row>

            {user?.user.role_id === 2 ?

                <Row>
                    <Col sm={12} className="col-12">
                        <Card>
                            <Card.Body>
                                <Row id="user-profile">
                                    <Col lg={12} xl={4} md={12} sm={12}>
                                        <Card className="profile-edit">
                                            <Card.Header style={{ background: '#0A7E51' }}>
                                                <Card.Title style={{ color: 'white' }}>Change Password</Card.Title>
                                            </Card.Header>

                                            <Card.Body>

                                                <FormGroup>
                                                    <Form.Label className="form-label">Change Password</Form.Label>
                                                    <FormControl
                                                        type="password"
                                                        className="form-control"
                                                        name="password"
                                                        defaultValue={value.password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label className="form-label">New Password</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        className="form-control"
                                                        name="newPassword"
                                                        defaultValue={value.newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label className="form-label">Confirm Password</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        className="form-control"
                                                        name="confirmPassword"
                                                        defaultValue={value.confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Card.Body>
                                            <Card.Footer className="text-end">
                                                <Link to="#" className="btn btn-primary me-2"
                                                    onClick={() => {
                                                        submitChangePassword();
                                                    }}
                                                >
                                                    Update
                                                </Link>
                                            </Card.Footer>
                                        </Card>
                                        <Card className="panel-theme">
                                            <Card.Header style={{ background: '#0A7E51' }}>
                                                <div className="float-start">
                                                    <Card.Title as="h3" style={{ color: 'white' }}>Contact</Card.Title>
                                                </div>
                                                <div className="clearfix"></div>
                                            </Card.Header>
                                            <Card.Body className="no-padding">
                                                <ListGroup className="no-margin">
                                                    <ListGroup.Item className="list-group-item">
                                                        <i className="fa fa-envelope list-contact-icons border text-center br-100"></i>
                                                        <span className="contact-icons">support@lawyerenrollment.com.ng</span>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="list-group-item">
                                                        <i className="fa fa-globe list-contact-icons border text-center br-100"></i>
                                                        <span className="contact-icons">lawyerenrollment.com.ng</span>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="list-group-item">
                                                        <i className="fa fa-phone list-contact-icons border text-center br-100"></i>
                                                        <span className="contact-icons">+000 0000 0000 </span>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col lg={12} md={12} xl={8}>
                                        <Card className=" bg-transparent shadow-none border-0">
                                            <Card.Header style={{ background: '#0A7E51' }}>
                                                <Card.Title style={{ color: 'white' }}>Profile Information</Card.Title>
                                            </Card.Header>
                                            <div className="border-top">
                                                <div className="wideget-user-tab">
                                                    <div className="tab-menu-heading">
                                                        <div className="tabs-menu1 profiletabs">
                                                            <Tabs
                                                                variant="Tabs"
                                                                defaultActiveKey="Profile"
                                                                id=" tab-51"
                                                                className="tab-content tabesbody "
                                                            >
                                                                <Tab eventKey="Profile" title="Profile">
                                                                    <div className="tab-pane profiletab show">
                                                                        <div id="profile-log-switch">
                                                                            <Card>
                                                                                {loading && <Loader />}
                                                                                {!loading &&
                                                                                    <Card.Body className="bg-white">
                                                                                        <div className="media-heading">
                                                                                            <h5 style={{ color: '#0A7E51' }}>
                                                                                                <strong>Personal Information</strong>
                                                                                            </h5>
                                                                                        </div>
                                                                                        <div className="table-responsive p-1">
                                                                                            <Table className="table row table-borderless">
                                                                                                <tbody className="col-lg-12 col-xl-6 p-0">
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <strong>Full Name : </strong>
                                                                                                            {data?.Title ? data.Title.name : ""} {`${data?.surname} ${data?.first_name} ${data?.middle_name}`}
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <strong>Gender : </strong>
                                                                                                            {data?.gender}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <strong>State of Origin : </strong>
                                                                                                            {data?.State ? data?.State.name : ""}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <strong>Nationality : </strong>
                                                                                                            {data?.Country ? data?.Country.name : ""}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <strong>Date of Birth : </strong>
                                                                                                            {formatDate(data?.date_of_birth)}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                                <tbody className="col-lg-12 col-xl-6 p-0">

                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <strong>Email : </strong>
                                                                                                            {data?.email}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <strong>Phone : </strong>
                                                                                                            {data?.phone}
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <strong>  Law School Campus Attended : </strong>
                                                                                                            {data?.law_sch_camp_attended}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <strong>  University Attended : </strong>
                                                                                                            {data?.university_attended}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <strong>Date of Placement : </strong>
                                                                                                            {data?.date_of_placement && formatDate(data?.date_of_placement)}
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <strong>Signature : </strong>
                                                                                                            {data ?
                                                                                                                <img src={process.env.REACT_APP_UPLOAD_URL + data.speciment_signature} crossOrigin="anonymous" alt="Signature.."
                                                                                                                    width={'50px'}
                                                                                                                />
                                                                                                                : 'Signature...'}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </Table>
                                                                                        </div>
                                                                                        <Row className="row profie-img">
                                                                                            <Col md={12}>
                                                                                                <div className="media-heading">
                                                                                                    <h5 style={{ color: '#0A7E51' }}>
                                                                                                        <strong> Permanent Home Address</strong>
                                                                                                    </h5>
                                                                                                </div>
                                                                                                <p>
                                                                                                    {data?.permanent_home_address}
                                                                                                </p>

                                                                                                <div className="media-heading">
                                                                                                    <h5 style={{ color: '#0A7E51' }}>
                                                                                                        <strong> Contact Address</strong>
                                                                                                    </h5>
                                                                                                </div>

                                                                                                <p className="mb-0">
                                                                                                    {data?.contact_address}
                                                                                                </p>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <br />
                                                                                        <Row className="row profie-img">
                                                                                            <Col md={12}>
                                                                                                <a
                                                                                                    href={process.env.REACT_APP_UPLOAD_URL + data?.call_to_bar_cert_dir}
                                                                                                    className="btn btn-secondary me-2"
                                                                                                    target="_blank"
                                                                                                    rel="noopener noreferrer"
                                                                                                    style={{ border: 'white' }}
                                                                                                >
                                                                                                    View Call to Bar Certificate
                                                                                                </a>

                                                                                                {/* <Link to="#" className="btn btn-primary me-2">
                                                                                                Updated
                                                                                            </Link> */}
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Card.Body>
                                                                                }
                                                                            </Card>
                                                                        </div>
                                                                    </div>
                                                                </Tab>
                                                            </Tabs>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                :
                <Row>
                    <Col sm={12} className="col-12">
                        <Card>
                            <Card.Body>
                                <Row id="user-profile">
                                    <Col lg={6} xl={6} md={6} sm={6}>
                                        <Card className="profile-edit">
                                            <Card.Header style={{ background: '#0A7E51' }}>
                                                <Card.Title style={{ color: 'white' }}>Change Password</Card.Title>
                                            </Card.Header>

                                            <Card.Body>

                                                <FormGroup>
                                                    <Form.Label className="form-label">Change Password</Form.Label>
                                                    <FormControl
                                                        type="password"
                                                        className="form-control"
                                                        name="password"
                                                        defaultValue={value.password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label className="form-label">New Password</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        className="form-control"
                                                        name="newPassword"
                                                        defaultValue={value.newPassword}
                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <Form.Label className="form-label">Confirm Password</Form.Label>
                                                    <Form.Control
                                                        type="password"
                                                        className="form-control"
                                                        name="confirmPassword"
                                                        defaultValue={value.confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                    />
                                                </FormGroup>
                                            </Card.Body>
                                            <Card.Footer className="text-end">
                                                <Link to="#" className="btn btn-primary me-2"
                                                    onClick={() => {
                                                        submitChangePassword();
                                                    }}
                                                >
                                                    Update
                                                </Link>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                    <Col lg={6} xl={6} md={6} sm={6}>
                                        <Card className="panel-theme">
                                            <Card.Header style={{ background: '#0A7E51' }}>
                                                <div className="float-start">
                                                    <Card.Title as="h3" style={{ color: 'white' }}>Contact</Card.Title>
                                                </div>
                                                <div className="clearfix"></div>
                                            </Card.Header>
                                            <Card.Body className="no-padding">
                                                <ListGroup className="no-margin">
                                                    <ListGroup.Item className="list-group-item">
                                                        <i className="fa fa-envelope list-contact-icons border text-center br-100"></i>
                                                        <span className="contact-icons">support@lawyerenrollment.com.ng</span>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="list-group-item">
                                                        <i className="fa fa-globe list-contact-icons border text-center br-100"></i>
                                                        <span className="contact-icons"> lawyerenrollment.com.ng</span>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className="list-group-item">
                                                        <i className="fa fa-phone list-contact-icons border text-center br-100"></i>
                                                        <span className="contact-icons">+000 0000 0000 </span>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            }


        </div >
    );
}
