import React from "react";
import { useState, useContext, useEffect, useRef } from "react";
import {
  Card,
  Row,
  Col,
  Modal,
  Button,
  Form,
  FormGroup,
  Badge,
} from "react-bootstrap";
import endpoint from "../../../context/endpoint";
import { ErrorAlert, SuccessAlert } from "../../../data/Toast/toast";
import { Link, useParams } from "react-router-dom";
import { GraduandListData } from "../../../data/Graduands/GraduandListData";

const GraduandslList = () => {
  const { volume_no } = useParams();

  return (
    <div>
      <GraduandListData />
    </div>
  );
};

export default GraduandslList;
