import React from 'react'
import { Breadcrumb, Card, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScnLogo from './assets/img/scnlogo.png'
import { FaCaretRight } from "react-icons/fa";

const Footer = () => {
  const year = new Date()
	const curYear = year.getFullYear()
  return (
    <>
      <div>
        <div
          className="container-fluid bg-dark text-light footer mt-5 py-0 wow fadeIn"
          data-wow-delay="0.1s">
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-4 col-md-6">
                <img
                  className="m-0"
                  src={ScnLogo}
                  style={{ width: `80px`, height: `70px` }}
                />
                <p className="mb-2">
                  <i className="fa fa-map-marker-alt me-3" />
                  Three Arms Zone, PMB 308, Abuja, Nigeria
                </p>
                {/* <p className="mb-2">
                  <i className="fa fa-phone-alt me-3" />
                  +2349034453232
                </p> */}
                <p className="mb-2">
                  <i className="fa fa-envelope me-3" />
                  support@lawyerenrollment.com.ng
                </p>
                <div className="d-flex pt-2">
                  <a
                    className="btn btn-square btn-outline-light rounded-circle me-2"
                    href="#">
                    <i className="fa fa-twitter" />
                  </a>
                  <a
                    className="btn btn-square btn-outline-light rounded-circle me-2"
                    href="#">
                    <i className="fa fa-facebook-f" />
                  </a>
                  <a
                    className="btn btn-square btn-outline-light rounded-circle me-2"
                    href="#">
                    <i className="fa fa-youtube" />
                  </a>
                  <a
                    className="btn btn-square btn-outline-light rounded-circle me-2"
                    href="#">
                    <i className="fa fa-linkedin" />
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <h4 className="text-white mb-4">Services</h4>
                <a
                  className="btn text-white"
                  href="#">
                  <FaCaretRight /> Enrollment
                </a>
                <br />
                <a
                  className="btn text-white"
                  href="#">
                  <FaCaretRight /> Verification
                </a>
              </div>
              <div className="col-lg-4 col-md-6">
                <h4 className="text-white mb-4">Quick Links</h4>
                <a
                  className="btn text-white"
                  href="/about">
                  <FaCaretRight /> About Us
                </a>
                <br />
                <a
                  className="btn text-white"
                  href="/instructions">
                  <FaCaretRight /> How To
                </a>
                <br />
                <a
                  className="btn text-white"
                  href="/contact">
                  <FaCaretRight /> Contact Us
                </a>
                <br />
                <a
                  className="btn text-white"
                  href="#">
                  <FaCaretRight /> Our Services
                </a>
                <br />
                <a
                  className="btn text-white"
                  href="#">
                  <FaCaretRight /> Support
                </a>
                
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid copyright py-4">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                © {curYear} Supreme court enrollment app, All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end">
                Designed By{" "}
                <a
                  className="border-bottom"
                  href="https://mbrcomputers.com/">
                  Mbr Computer Consultants ltd
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer