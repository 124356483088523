import React from "react";
import { useState, useContext, useEffect, useRef } from "react";
import endpoint from "../../context/endpoint";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../data/Toast/toast";
import PassportCard from "../Scn-Components/PassportCard";
import SignatureCard from "../Scn-Components/SignatureCard";

import EnrollmentStatus from "./EnrollmentStatus";
import avatarNoImg from "../Scn-Components/avatarNoImg3.jpg";
import { Card, Col, Modal, Button } from "react-bootstrap";
import { generate } from "shortid";
import Loader from "../../data/Loader/loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SignatureCanvas from "react-signature-canvas";
import "./passportstyes.css";
// import PdfPreviewer from "../Scn-Components/DocimentsPreviewer/PdfPreviewer";
// import DocPreviwer from "../Scn-Components/DocimentsPreviewer/DocPreviwer";


const Enrollment = () => {
  const [loading, setLoading] = useState(false);
  const [titleList, setTitle] = useState([]);
  const [countryList, setCountry] = useState([]);
  const [statesList, setState] = useState([]);
  const [lgasList, setLga] = useState([]);
  const [submitButton, setSubmitButton] = useState(false);
  const [url, setUrl] = useState();
  const navigate = useNavigate();

  const displaySubmitButton = () => {
    setSubmitButton(!submitButton);
  };

  const fileInputRef = useRef(null);
  const signatureRef = useRef(null);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleSigClick = () => {
    signatureRef.current.click();
  };

  const [viewComment, setViewComment] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [startModal, setStartModal] = useState(true);


  const [expectedDocs, setExpectedDocs] = useState([
    {
      attachmentId: expectedDocs ? expectedDocs.id : "",
      doc_name: "",
      doc_url: null,
    },
  ]);
  const [startDate, setStartDate] = useState(); //new Date()
  const [cert, setCert] = useState({
    year_of_call_to_bar: "",
    call_to_bar: "",
    doc_url: "",
  });
  const [lateDoc, setLateDoc] = useState({
    late_enroll_doc: "",
  });
  const [signature, setSignature] = useState({
    upload: "",
    sign: "",
  });


  const params = useParams();

  const [enroll, setEnroll] = useState({
    title_id: "",
    graduand_id: params.id,
    surname: "",
    first_name: "",
    middle_name: "",
    passport_url: null,
    gender: "",
    country_id: "",
    state_id: "",
    new_state: "",
    lga_id: "",
    date_of_birth: "",
    university_attended: "",
    permanent_home_address: "",
    contact_address: "",
    specimen_signature: "",
    year_of_call_to_bar: "",
    law_sch_camp_attended: "",
    year_of_admission_law_sch: "",
    email: "",
    phone: "",
    alternative_name: "",
    status_id: "",
    call_to_bar_cert_dir: "",
    late_enrollment_proof: "",
    doc_url: "",
  });

  useEffect(() => {
    getCountries();
    getTitles();
    getUser();
  }, []);

  const getUser = async () => {
    setLoading(true);
    await endpoint
      .get(`/enrollment/show/graduand/${params.id}`)
      .then((res) => {
        setLoading(false);
        console.log("data enrollment", res.data.data);
        handleChangeCountry(res.data.data.Country?.id);
        setEnroll(res.data.data);
        // setStartDate(res.data.data.year_of_call_to_bar)

        // setEnrollCard(res.data.data.identification_card)
        if (
          res.data.data.passport_url == null ||
          res.data.data.passport_url == ""
        ) {
          setPreviewImage(avatarNoImg);
        }
        getExpectedEnrollmentDocuments();
        handleChange(res.data.data.state_id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getExpectedEnrollmentDocuments = async () => {
    await endpoint
      .get(`/enrollmentdocument/list-expected-enrollment-document`)
      .then((res) => {
        console.log("expected docs", res.data);
        setExpectedDocs(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCountries = async () => {
    await endpoint
      .get(`/country/list`)
      .then((res) => {
        // console.log("country list data", res.data.data);
        setCountry(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getTitles = async () => {
    await endpoint
      .get(`/title/list`)
      .then((res) => {
        // console.log("title list data", res.data.data);
        setTitle(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  //fetch state on country
  const handleChangeCountry = async (id) => {
    await endpoint
      .get(`/state/list/${id}`)
      .then((res) => {
        console.log("my state list", res.data.data);
        setState(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  //fetch lga on state
  const handleChange = async (id) => {
    console.log("state_id", id);
    await endpoint
      .get(`/state/show/${id}`)
      .then((res) => {
        console.log(res.data.data.Lgas);
        setLga(res.data.data.Lgas);
      })
      .catch((err) => console.log(err));
  };

  const [previewImage, setPreviewImage] = useState(null);
  const getPreview = (targetFile) => {
    if (targetFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(targetFile);
    } else {
      setPreviewImage(null);
    }
  };

  const [previewSignature, setPreviewSignature] = useState(null);
  const getSignature = (targetFile) => {
    if (targetFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewSignature(reader.result);
      };
      reader.readAsDataURL(targetFile);
    } else {
      setPreviewSignature(null);
    }
  };

  

  const ShowFinalModal = () => {
    setSubmit(true);
  }

    const HideStartModal = () => {
      setStartModal(false);
    };
   const HideFinalModal = () => {
     setSubmit(false);
   };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const data = new FormData();
      data.append("title_id", enroll.title_id);
      data.append("graduand_id", params.id);
      data.append("surname", enroll.surname);
      data.append("first_name", enroll.first_name);
      data.append("middle_name", enroll.middle_name);
      data.append("email", enroll.email);
      data.append("phone", enroll.phone);
      data.append("passport_url", enroll.passport_url);
      data.append("gender", enroll.gender);
      data.append("country_id", enroll.country_id);
      data.append("state_id", enroll.state_id);
      data.append("new_state", enroll.new_state);
      data.append("lga_id", enroll.lga_id ? enroll.lga_id : "");
      data.append("date_of_birth", enroll.date_of_birth);
      data.append("permanent_home_address", enroll.permanent_home_address);
      data.append("contact_address", enroll.contact_address);
      data.append("university_attended", enroll.university_attended);
      data.append("year_of_call_to_bar", cert.year_of_call_to_bar);
      data.append("year_of_graduation", enroll.year_of_graduation);
      data.append("law_sch_camp_attended", enroll.law_sch_camp_attended);
      data.append("call_to_bar_cert", cert.call_to_bar);
      data.append("late_enrollment_proof", lateDoc.late_enroll_doc);
      data.append("enrollment_doc_url", cert.doc_url);
      data.append("expected_enrollment_doc_id", 2);
      data.append("specimen_signature", signature.upload);

      await endpoint.post(`/enrollment/create`, data).then((res) => {
        setLoading(false);
        setEnroll(res.data.data);
        navigate("/enrollment-search");
        SuccessAlert(res.data.message);
      });
    } catch (error) {
      setLoading(false);
      ErrorAlert(error.response.data.message);
      console.error("Error creating enrollment:", error);
    }
  };

  const formattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (date) => {
    const newDate = date;
    const format = formattedDate(newDate);
    setCert((prevState) => ({
      ...prevState,
      year_of_call_to_bar: format,
    }));
  };



  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <>
      <div>
        <div
          className="container-fluid page-header py-5 mb-5 wow fadeIn "
          data-wow-delay="0.1s">
          <div className="container text-center py-5">
            <h1 className="display-3 text-white mb-4 animated slideInDown">
              Enrollment Page
            </h1>
          </div>
        </div>

        <div className="container-fluid py-5 ">
          <div className="container ">
            <div
              className="text-center mx-auto wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                maxWidth: "500px",
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}>
              <p className="fs-5 fw-bold text-primary">
                To enroll, enter your details below
              </p>
              <h1 className="display-5">Enrollment Form</h1>
              <div className="mb-5 fw-bold">
                <span className="text-primary ">
                  Please check your mail/spam subsequently for the status of
                  your application & next actions.
                </span>{" "}
              </div>
            </div>
            <div className="row justify-content-center ">
              <div className="col-lg-8">
                {loading && <Loader />}
                {!loading && (
                  <>
                    <form
                      className="form-horizontal "
                      onSubmit={handleSubmit}
                      encType="multipart/form-data">
                      <div
                        className="custom-bg rounded p-4 p-sm-5 wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{
                          visibility: "visible",
                          animationDdelay: "0.1s",
                          animationName: "fadeInUp",
                        }}>
                        <div className="row mb-2">
                          {enroll.Profile?.enrollment_number && (
                            <div className="text-center mb-2">
                              <Link
                                to={`/enrollment-print-confirmation/${params.id}`}
                                className="mb-2">
                                <button
                                  className="btn btn-sm btn-primary"
                                  variant="secondary"
                                  title="Action"
                                  size="sm">
                                  <span className="fa fa-eye"></span>
                                  View Certificate
                                </button>
                              </Link>
                            </div>
                          )}

                          {enroll.hasOwnProperty("graduand") ||
                          enroll.status_id == 10 ||
                          enroll.status_id == 0 ? (
                            <div className="col">
                              <label>
                                Upload Passport{" "}
                                <i>
                                  <strong>**click the photo icon**</strong>
                                </i>
                              </label>{" "}
                              <br />
                              <span>
                                <strong
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                  }}>
                                  Note
                                </strong>
                                :{" "}
                                <i
                                  style={{
                                    color: "black",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                  }}>
                                  Accepted file formats are .jpg, .png, .jpeg,
                                  and the file size should not exceed 50KB.
                                </i>
                              </span>
                              <div className="col text-center">
                                {previewImage ? (
                                  <div onClick={handleDivClick}>
                                    <PassportCard image={previewImage} />
                                  </div>
                                ) : enroll.passport_url !== null ? (
                                  // <div
                                  //   className="passport-placeholder "
                                  //   onClick={handleDivClick}>
                                  //   Click to Select Passport 2
                                  // </div>
                                  <div onClick={handleDivClick}>
                                    <PassportCard
                                      image={
                                        process.env.REACT_APP_UPLOAD_URL +
                                        enroll.passport_url
                                      }
                                    />
                                  </div>
                                ) : (
                                  " "
                                )}
                              </div>
                              <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  getPreview(e.target.files[0]);
                                  setEnroll({
                                    ...enroll,
                                    passport_url: e.target.files[0],
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            <div className="col">
                              <PassportCard
                                image={
                                  process.env.REACT_APP_UPLOAD_URL +
                                  enroll.passport_url
                                }
                              />{" "}
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-md-12 mt-2">
                            <label className="custom-bold">Title</label>
                            <select
                              className="form-select"
                              style={{ width: "100%", marginTop: "0px" }}
                              onChange={(e) => {
                                setEnroll({
                                  ...enroll,
                                  title_id: e.target.value,
                                });
                              }}
                              required
                              value={enroll.title_id}
                              disabled={
                                enroll.status_id &&
                                !(enroll.status_id == 10 || 0)
                              }>
                              <option value="">---Select Title---</option>
                              {titleList.map((title) => (
                                <option
                                  key={title.id}
                                  value={title.id}>
                                  {title.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-12 m-1">
                            <label className="custom-bold">Surname</label>
                            <br></br>
                            <div className="form-control">
                              {enroll.surname ? enroll.surname : ""}
                            </div>
                          </div>

                          <div className="col-md-12 m-1">
                            <label className="custom-bold">First Name</label>
                            <br></br>
                            <div className="form-control">
                              {enroll.first_name ? enroll.first_name : ""}
                            </div>
                          </div>

                          <div className="col-md-12 m-1">
                            <label className="custom-bold">Middle Name</label>
                            <br></br>
                            <div className="form-control">
                              {enroll.middle_name ? enroll.middle_name : ""}
                            </div>
                          </div>
                          <div className="col-md-12 m-1">
                            <label className="custom-bold">Email</label>

                            <input
                              onChange={(e) => {
                                setEnroll({
                                  ...enroll,
                                  email: e.target.value,
                                });
                              }}
                              className="form-control"
                              type="text"
                              name=""
                              id=""
                              defaultValue={enroll.email ? enroll.email : ""}
                            />
                          </div>

                          <div className="col-md-12 m-1">
                            <label className="custom-bold">Phone Number</label>

                            <input
                              onChange={(e) => {
                                setEnroll({
                                  ...enroll,
                                  phone: e.target.value,
                                });
                              }}
                              className="form-control"
                              type="text"
                              name=""
                              id=""
                              defaultValue={enroll.phone ? enroll.phone : ""}
                            />
                          </div>

                          <div className="col-md-12 m-1">
                            <label className="custom-bold">Gender</label>
                            <div className="form-control">
                              {enroll.gender ? enroll.gender : ""}
                            </div>
                          </div>

                          <div className="col-md-12 m-1">
                            <label className="custom-bold">
                              Country <span className="text-danger">*</span>
                            </label>
                            <select
                              className="form-select"
                              style={{ width: "100%", marginTop: "0px" }}
                              onChange={(e) => {
                                handleChangeCountry(e.target.value);
                                setEnroll({
                                  ...enroll,
                                  country_id: e.target.value,
                                });
                              }}
                              value={enroll.country_id}
                              disabled={
                                enroll.status_id &&
                                !(enroll.status_id == 10 || 0)
                              }>
                              <option value="">---Select Country---</option>
                              {countryList.map((country) => (
                                <option
                                  key={country.id}
                                  value={country.id}>
                                  {country.country}
                                </option>
                              ))}
                            </select>
                          </div>

                          {enroll.country_id == 1 && (
                            <>
                              <div className="col-md-12 m-1">
                                <label className="custom-bold">
                                  State <span className="text-danger">*</span>
                                </label>
                                <select
                                  className="form-select"
                                  style={{ width: "100%", marginTop: "0px" }}
                                  onChange={(e) => {
                                    handleChange(e.target.value);
                                    setEnroll({
                                      ...enroll,
                                      state_id: e.target.value,
                                    });
                                  }}
                                  value={enroll.state_id}
                                  disabled={
                                    enroll.status_id &&
                                    !(enroll.status_id == 10 || 0)
                                  }>
                                  <option value="">---Select State--</option>
                                  {statesList.map((state) => (
                                    <option
                                      key={state.id}
                                      value={state.id}
                                      selected={enroll.state_id === state.id}>
                                      {state.state}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="col-md-12 m-1">
                                <label className="custom-bold">
                                  LGA <span className="text-danger">*</span>
                                </label>
                                <select
                                  className="form-select"
                                  style={{ width: "100%", marginTop: "0px" }}
                                  onChange={(e) => {
                                    setEnroll({
                                      ...enroll,
                                      lga_id: e.target.value,
                                    });
                                  }}
                                  value={enroll.lga_id}
                                  disabled={
                                    enroll.status_id &&
                                    !(enroll.status_id == 10 || 0)
                                  }>
                                  <option value="">---Select Lga---</option>
                                  {lgasList.length > 0
                                    ? lgasList.map((lga) => (
                                        <option
                                          key={lga.id}
                                          value={lga.id}
                                          selected={enroll.lga_id == lga.id}>
                                          {lga.lga}
                                        </option>
                                      ))
                                    : ""}
                                </select>
                              </div>
                            </>
                          )}

                          {enroll.country_id != 1 && (
                            <>
                              <div className="col-md-12 m-1">
                                <label className="custom-bold">
                                  State <span className="text-danger">*</span>
                                </label>
                                <select
                                  className="form-select"
                                  style={{ width: "100%", marginTop: "0px" }}
                                  onChange={(e) => {
                                    setEnroll({
                                      ...enroll,
                                      state_id: e.target.value,
                                    });
                                  }}
                                  value={enroll.state_id}
                                  disabled={
                                    enroll.status_id &&
                                    !(enroll.status_id == 10 || 0)
                                  }>
                                  <option value="">---Select State--</option>
                                  {statesList.map((state) => (
                                    <option
                                      key={state.id}
                                      value={state.id}
                                      selected={enroll.state_id === state.id}>
                                      {state.state}
                                    </option>
                                  ))}
                                  <option value="999999">Others</option>
                                </select>
                              </div>

                              {enroll.state_id == 999999 && (
                                <div className="col-md-12 m-1">
                                  <label className="custom-bold">
                                    Please Enter your state
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={enroll.new_state}
                                    onChange={(e) =>
                                      setEnroll({
                                        ...enroll,
                                        new_state: e.target.value,
                                      })
                                    }
                                    readOnly={
                                      enroll.status_id &&
                                      !(enroll.status_id == 10 || 0)
                                    }
                                  />
                                </div>
                              )}
                            </>
                          )}

                          <div className="col-md-12 m-1">
                            <label className="custom-bold">Date of Birth</label>
                            <div className="form-control">
                              {enroll.date_of_birth
                                ? formatDate(enroll.date_of_birth)
                                : ""}
                            </div>
                          </div>

                          <div className="col-md-12 m-1">
                            <label className="custom-bold">
                              Permanent Home Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={enroll.permanent_home_address}
                              onChange={(e) =>
                                setEnroll({
                                  ...enroll,
                                  permanent_home_address: e.target.value,
                                })
                              }
                              readOnly={
                                enroll.status_id &&
                                !(enroll.status_id == 10 || 0)
                              }
                            />
                          </div>

                          <div className="col-md-12 m-1">
                            <label className="custom-bold">
                              Contact Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={enroll.contact_address}
                              onChange={(e) =>
                                setEnroll({
                                  ...enroll,
                                  contact_address: e.target.value,
                                })
                              }
                              readOnly={
                                enroll.status_id &&
                                !(enroll.status_id == 10 || 0)
                              }
                            />
                          </div>

                          <div className="col-md-12 m-1">
                            <label className="custom-bold">
                              University Attended
                            </label>
                            <div className="form-control">
                              {enroll.university_attended
                                ? enroll.university_attended
                                : ""}
                            </div>
                          </div>

                          <div className="col-md-12 m-1">
                            <label className="custom-bold">
                              Law School Campus Attended
                            </label>
                            <div className="form-control">
                              {enroll.law_sch_camp_attended
                                ? enroll.law_sch_camp_attended
                                : ""}
                            </div>
                          </div>

                          <div className="col-md-12 m-1">
                            <label className="custom-bold">
                              Year of Graduation
                            </label>
                            <div className="form-control">
                              {enroll.year_of_graduation
                                ? enroll.year_of_graduation
                                : ""}
                            </div>
                          </div>

                          {enroll.status_id == 1 || enroll.status_id == 3 ? (
                            <>
                              {enroll.call_to_bar_cert_dir && (
                                <>
                                  <div className="row mt-4">
                                    <div className="col-md-12 mb-2">
                                      <a
                                        href={`${
                                          process.env.REACT_APP_UPLOAD_URL +
                                          enroll.call_to_bar_cert_dir
                                        }`}
                                        target="_blank"
                                        className="btn btn-sm btn-success">
                                        <span className="fa fa-eye"></span>{" "}
                                        Click to view Call to Bar Certificate
                                      </a>
                                    </div>
                                    <hr></hr>
                                  </div>
                                </>
                              )}

                              {enroll.hasOwnProperty("EnrollmentDocuments") && (
                                <div className="row">
                                  {expectedDocs &&
                                    expectedDocs
                                      .filter(
                                        (expected) => expected.status == 0
                                      )
                                      .map((expected) => (
                                        <>
                                          <div
                                            className="row"
                                            key={expected.id}>
                                            {enroll.hasOwnProperty(
                                              "EnrollmentDocuments"
                                            ) &&
                                              enroll.EnrollmentDocuments.map(
                                                (doc) =>
                                                  doc.ExpectedEnrollmentDoc
                                                    ?.id == expected.id ? (
                                                    <>
                                                      <div className="col-md-12 mb-2">
                                                        <div className="">
                                                          <a
                                                            href={`${
                                                              process.env
                                                                .REACT_APP_UPLOAD_URL +
                                                              doc.doc_url
                                                            }`}
                                                            target="_blank"
                                                            className="btn btn-sm btn-success">
                                                            <span className="fa fa-eye"></span>{" "}
                                                            Click to view{" "}
                                                            {expected.name}
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )
                                              )}
                                          </div>
                                          <hr></hr>
                                        </>
                                      ))}
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="col-md-12 mt-4 mb-0">
                                <label className="custom-bold">
                                  Call to Bar Certificate
                                </label>{" "}
                                <br />
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => {
                                    setCert({
                                      ...cert,
                                      call_to_bar: e.target.files[0],
                                    });
                                  }}
                                />
                                <div className="mt-2">
                                  {enroll.call_to_bar_cert_dir && (
                                    <a
                                      href={`${
                                        process.env.REACT_APP_UPLOAD_URL +
                                        enroll.call_to_bar_cert_dir
                                      }`}
                                      target="_blank"
                                      className="btn btn-sm btn-success">
                                      <span className="fa fa-eye"></span> Click
                                      to view Call to Bar Certificate
                                    </a>
                                  )}
                                </div>
                              </div>
                              <hr></hr>
                              <div className="col-md-12 mb-0">
                                <label className="custom-bold">
                                  Qualification Certificate
                                </label>{" "}
                                <br />
                                <span></span>
                                <input
                                  type="file"
                                  className="form-control"
                                  required
                                  onChange={(e) => {
                                    setCert({
                                      ...cert,
                                      doc_url: e.target.files[0],
                                    });
                                  }}
                                />
                                <div className="mt-2">
                                  {enroll.hasOwnProperty(
                                    "EnrollmentDocuments"
                                  ) && (
                                    <div className="row">
                                      {expectedDocs &&
                                        expectedDocs
                                          .filter(
                                            (expected) => expected.status == 0
                                          )
                                          .map((expected) => (
                                            <>
                                              <div
                                                className="row"
                                                key={expected.id}>
                                                {enroll.hasOwnProperty(
                                                  "EnrollmentDocuments"
                                                ) &&
                                                  enroll.EnrollmentDocuments.map(
                                                    (doc) =>
                                                      doc.ExpectedEnrollmentDoc
                                                        ?.id == expected.id ? (
                                                        <>
                                                          <div className="col-md-12 mb-2">
                                                            <div className="">
                                                              <a
                                                                href={`${
                                                                  process.env
                                                                    .REACT_APP_UPLOAD_URL +
                                                                  doc.doc_url
                                                                }`}
                                                                target="_blank"
                                                                className="btn btn-sm btn-success">
                                                                <span className="fa fa-eye"></span>{" "}
                                                                Click to view{" "}
                                                                {expected.name}
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )
                                                  )}
                                              </div>
                                              <hr></hr>
                                            </>
                                          ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {(enroll.Graduand && enroll.Graduand.is_late_enrollment == 1) &&
                              <>
                                <div className="col-md-12 mb-0">
                                  <label className="custom-bold">
                                    Late enrollment document
                                  </label>{" "}
                                  <br />
                                  <span></span>
                                  <input
                                    type="file"
                                    className="form-control"
                                    required
                                    onChange={(e) => {
                                      setLateDoc({
                                        ...lateDoc,
                                        late_enroll_doc: e.target.files[0],
                                      });
                                    }}
                                  />
                                  <div className="mt-2">
                                    {enroll.late_enrollment_proof && (
                                      <a
                                        href={`${
                                          process.env.REACT_APP_UPLOAD_URL +
                                          enroll.late_enrollment_proof
                                        }`}
                                        target="_blank"
                                        className="btn btn-sm btn-success">
                                        <span className="fa fa-eye"></span> Click
                                        to view late enrollment document
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </>}
                              <hr></hr>

                              <div className="col-md-12">
                                <label className="custom-bold">
                                  Year of Call to Bar (dd/mm/yyyy)
                                </label>
                              </div>
                              <div className="col-md-12 customDatePickerWidth">
                                <DatePicker
                                  required
                                  className="form-control"
                                  dateFormat="dd/MM/yyyy"
                                  selected={startDate}
                                  onChange={(date) => {
                                    setStartDate(date);
                                    handleDateChange(date);
                                  }}
                                />
                              </div>
                            </>
                          )}

                          {enroll.status_id == 1 || enroll.status_id == 3 ? (
                            <>
                              {(previewSignature ||
                                url ||
                                enroll.specimen_signature) && (
                                <>
                                  <span>Signed...</span>
                                  <br />
                                  <div
                                    className="col-md-12 mt-2 mb-2 justify-content-center"
                                    onClick={handleSigClick}>
                                    <SignatureCard
                                      image={
                                        previewSignature ||
                                        url ||
                                        (enroll.specimen_signature
                                          ? process.env.REACT_APP_UPLOAD_URL +
                                            enroll.specimen_signature
                                          : "")
                                      }
                                    />
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="col-md-12 mt-4 mb-2">
                                <label className="custom-bold">
                                  Upload your Signature
                                  <i>
                                    <strong>**click the signature card**</strong>
                                  </i>
                                </label>{" "}
                                <br />
                                <span>
                                  <strong style={{ fontSize: "12px" }}>
                                    Note
                                  </strong>
                                  :{" "}
                                  <i
                                    style={{
                                      color: "black",
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      opacity: "1",
                                    }}>
                                    Accepted file formats are .jpg, .png,
                                    .jpeg, and the file size should not
                                    exceed 50KB.
                                  </i>
                                </span>
                                {previewSignature || enroll.specimen_signature == null ? (
                                  <>
                                    <div
                                      className="col-md-12 mt-2 mb-2 justify-content-center"
                                      onClick={handleSigClick}>
                                      <SignatureCard
                                        image={
                                          previewSignature ||
                                          url ||
                                          (enroll.specimen_signature
                                            ? process.env.REACT_APP_UPLOAD_URL +
                                              enroll.specimen_signature
                                            : "")
                                        }
                                      />
                                    </div>
                                  </>
                                ) : enroll.specimen_signature !== null ? (
                              
                                  <div
                                    className="col-md-12 mt-2 mb-2 justify-content-center"
                                    onClick={handleSigClick}>
                                    <SignatureCard
                                      image={
                                        previewSignature ||
                                        url ||
                                        (enroll.specimen_signature
                                          ? process.env.REACT_APP_UPLOAD_URL +
                                            enroll.specimen_signature
                                          : "")
                                      }
                                    />
                                  </div>
                                ) : (
                                  " "
                                )}
                                <input
                                  type="file"
                                  ref={signatureRef}
                                  style={{ display: "none" }}
                                  onChange={(e) => {
                                    getSignature(e.target.files[0]);
                                    setSignature({
                                      ...signature,
                                      upload: e.target.files[0],
                                    });
                                  }}
                                />
                              </div>
                            </>
                          )}
                          {((enroll.payment_status == 1) ||
                          (enroll.status_id == 10)) && (<>
                            <div className="row">
                              <p
                                className="custom-bold"
                                style={{ color: "red" }}>
                                Pls check the box below to confirm that you have
                                completed the form
                              </p>
                            </div>
                            <input
                              onChange={displaySubmitButton}
                              type="checkbox"
                              name=""
                              id=""
                              className="form-check"
                            />
                          </>)}
                          {/* <input type="check" className="form-check" /> */}
                        </div>

                        {enroll.hasOwnProperty("EnrollmentDocuments") &&
                          enroll.Comments.length > 0 && (
                            <>
                              <div className="row mt-2">
                                <div className="pt-2">
                                  <button
                                    className="btn btn-success m-1 pull-right"
                                    type="button"
                                    style={{ border: `inherit` }}
                                    onClick={(e) => {
                                      setViewComment(!viewComment);
                                    }}>
                                    <span className="fa fa-list"></span>{" "}
                                    Comments
                                  </button>
                                </div>
                              </div>

                              {/* viewComment */}
                              <Modal show={viewComment}>
                                <Modal.Body className="text-center p-4">
                                  <Button
                                    onClick={() => setViewComment(!viewComment)}
                                    className="btn-close"
                                    variant="">
                                    x
                                  </Button>
                                  <h4 className="text-success mb-4">
                                    Comment's
                                  </h4>
                                  <div className="mb-4">
                                    <div className="col-md-12">
                                      {enroll.Comments.map((comm) => (
                                        <div
                                          className="row border"
                                          key={comm.id}>
                                          <div
                                            className="fw-bold col-md-4"
                                            style={{ textAlign: `left` }}>
                                            Comment:
                                          </div>
                                          <div className="col-md-8">
                                            {comm.content} <br></br>
                                            <span
                                              className="text-success"
                                              style={{ fontSize: `14px` }}>
                                              {" "}
                                              <i>{comm.createdAt}</i>{" "}
                                            </span>
                                            <span style={{ fontSize: `14px` }}>
                                              <i>By: {comm.User.fullname}</i>
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>

                                  <button
                                    className="btn btn-danger pd-x-25 m-1"
                                    onClick={() =>
                                      setViewComment(!viewComment)
                                    }>
                                    Close
                                  </button>
                                </Modal.Body>
                              </Modal>
                              {/* end viewComment */}
                            </>
                          )}
                        <Modal show={submit}>
                          <Modal.Body className="text-center p-4">
                            <Button
                              onClick={HideFinalModal}
                              className="btn-close"
                              variant="">
                              x
                            </Button>
                            <h4 className="text-success mb-4">
                              Confirmation{" "}
                              <i className="fa fa-check-circle me-2"></i>
                            </h4>
                            <div className="mb-4">
                              <p className="custom-bold">
                                {" "}
                                "Please confirm that you have completed and
                                filled in all the necessary requirements
                                accurately. Incorrect information will be
                                declined and will delay the enrollment process."
                              </p>
                            </div>

                            <button
                              className="btn btn-danger pd-x-25 m-1"
                              onClick={HideFinalModal}>
                              Close
                            </button>
                            <button
                              className="btn btn-primary pd-x-25 m-1"
                              onClick={handleSubmit}>
                              Save
                            </button>
                          </Modal.Body>
                        </Modal>
                        <Modal
                          show={startModal}
                          onHide={HideFinalModal}
                          centered>
                          <Modal.Body className="text-center p-4">
                            <Button
                              onClick={HideStartModal}
                              className="btn-close"
                              variant="">
                              <i className="fa fa-times-circle"></i>
                            </Button>
                            <h4 className="text-success mb-4">
                              <i className="fa fa-check-circle me-2"></i>
                              Confirmation
                            </h4>
                            <div className="mb-4">
                              <p className="custom-bold">
                                Please take a few moments to look at the
                                instructions properly before completeing the
                                form
                              </p>
                              <ul className="text-start">
                                <li className="custom-bold">
                                  <i className="fa fa-list-alt me-2"></i>
                                  Ensure all fields are filled out correctly.
                                </li>
                                <li className="custom-bold">
                                  <i className="fa fa-list-alt me-2"></i>
                                  Double-check the information for accuracy.
                                </li>
                                <li className="custom-bold">
                                  <i className="fa fa-list-alt me-2"></i>
                                  Attach all required documents.
                                </li>
                                <li className="custom-bold">
                                  <i className="fa fa-list-alt me-2"></i>
                                  Passport and Signature must not be more than
                                  50kb.
                                </li>
                                <li className="custom-bold">
                                  <i className="fa fa-list-alt me-2"></i>
                                  Other documents must not be more than 100kb.
                                </li>
                              </ul>
                              <p
                                className="custom-bold"
                                style={{ color: "red" }}>
                                Pls ensure that all information are correct
                                before submitting to prevent delay in enrollment
                              </p>
                            </div>
                            <div>
                              <Button
                                variant="danger"
                                className="m-1"
                                onClick={HideStartModal}>
                                <i className="fa fa-times-circle"></i>
                              </Button>
                            </div>
                          </Modal.Body>
                        </Modal>
                        {((submitButton && enroll.payment_status == 1) ||
                          (submitButton && enroll.status_id == 10)) && (
                          <div className="row mt-2">
                            <div className="pt-2">
                              <button
                                onClick={ShowFinalModal}
                                className="btn btn-dark btn-sm m-1 py-2 px-3 m-1"
                                type="button">
                                <span className="fa fa-save"></span> Submit
                              </button>
                            </div>
                            <span className="text-danger">
                              <i
                                style={{
                                  color: "black",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  opacity: "1",
                                }}>
                                NB: pls ensure that all your details are correct
                                and all documents have benn selected properly
                                before clicking on submit as this is not
                                reversible
                              </i>
                            </span>
                          </div>
                        )}
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Enrollment;
