import React from "react";
import { useState, useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import endpoint from "../../context/endpoint";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../data/Toast/toast";
import PassportCard from "../Scn-Components/PassportCard";
import { Context } from "../../context/Context";
import Loader from "../../data/Loader/loader";
import { Dropdown } from "react-bootstrap";
import {
    Tabs,
    Tab,
    OverlayTrigger,
    Tooltip,
    Breadcrumb,
    Card,
    Row,
    Col,
    Form,
    Button,
} from "react-bootstrap";
import * as formelement from "../../data/Form/formelement/formelement";

const ChangeOfName = () => {
    const { user } = useContext(Context);
    const { register, formState: { errors }, } = useForm();
    const [isLoading, setLoading] = useState(false);
    const [titleList, setTitle] = useState([]);
    const [countryList, setCountry] = useState([]);
    const [statesList, setState] = useState([]);
    const [lgasList, setLga] = useState([]);
    const [selected, setSelected] = useState('');
    const navigate = useNavigate()
    const params = useParams();
    const Navigate = useNavigate()


    const fileInputRef = useRef(null);

    const [profile, setProfile] = useState({
        title_id: "",
        user_id: "",
        profile_id: "",
        status_id: "",
        surname: "",
        first_name: "",
        middle_name: "",
        passport_url: null,
        gender: "",
        country_id: "",
        state_id: "",
        lga_id: "",
        contact_address: "",
        place_of_birth: "",
        date_of_birth: "",
        university_attended: "",
        permanent_home_address: "",
        year_of_call_to_bar: "",
        law_sch_camp_attended: "",
        year_of_admission_law_sch: "",
        // specimen_signature: null,
        email: "",
        phone: "",
        temp_profile_id: "",
        enrollment_id: "",
        doc_name: "",
        doc_url: null,
        alternative_name: "",
    });

    const gender = [
        { id: 1, value: "Male", label: "Male" },
        { id: 2, value: "Female", label: "Female" },
    ];

    useEffect(() => {
        getCountries();
        getTitles();
        allStates();
        getUser()
    }, []);



    const handleFileChange = (event) => {
        console.log("file", event.target.files[0])
        setProfile({ ...profile, doc_url: event.target.files[0] });
    };

    const getUser = async () => {
        setLoading(true)
        await endpoint.get(`/profile/show-edit-auth-profile`)
            .then((res) => {
                console.log("profile details", res.data.data)
                setProfile(res.data.data)
                handleChange(res.data.data.state_id)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
            })
    }


    const handleChange = async (id) => {
        await endpoint
            .get(`/state/show/${id}`)
            .then((res) => {
                console.log(res.data.data.Lgas);
                setLga(res.data.data.Lgas);
            })
            .catch((err) => console.log(err));
    };

    const getCountries = async () => {
        console.log("id", user.user.id)
        await endpoint
            .get(`/country/list`)
            .then((res) => {
                console.log("country list data", res.data.data);
                setCountry(res.data.data);
            })
            .catch((err) => console.log(err));
    };

    const getTitles = async () => {
        await endpoint
            .get(`/title/list`)
            .then((res) => {
                console.log("title list data", res.data.data);
                setTitle(res.data.data);
            })
            .catch((err) => console.log(err));
    };

    const allStates = async () => {
        await endpoint
            .get(`/state/list`)
            .then((res) => {
                console.log("state list data", res.data.data);
                setState(res.data.data);
            })
            .catch((err) => console.log(err));
    };


    const handleSave = async (e) => {
        e.preventDefault()
        const data = new FormData();
        data.append('title_id', profile.title_id);
        data.append('user_id', params.user_id);
        data.append('profile_id', profile.profile_id);
        data.append('surname', profile.surname);
        data.append('first_name', profile.first_name);
        data.append('middle_name', profile.middle_name);
        data.append('gender', profile.gender);
        data.append('country_id', profile.country_id);
        data.append('state_id', profile.state_id);
        data.append('lga_id', profile.lga_id);
        data.append('contact_address', profile.contact_address);
        data.append('place_of_birth', profile.place_of_birth);
        data.append('date_of_birth', profile.date_of_birth);
        data.append('permanent_home_address', profile.permanent_home_address);
        data.append('university_attended', profile.university_attended);
        data.append('year_of_call_to_bar', profile.year_of_call_to_bar);
        data.append('law_sch_camp_attended', profile.law_sch_camp_attended);
        // data.append('year_of_admission_law_sch', profile.year_of_admission_law_sch);
        data.append('email', profile.email);
        data.append('phone', profile.phone);
        data.append('temp_profile_id', profile.temp_profile_id);
        data.append('enrollment_id', profile.enrollment_id);
        data.append('doc_name', profile.doc_name);
        data.append('doc_url', profile.doc_url);
        data.append('alternative_name', profile.alternative_name);
        setLoading(true)
        await endpoint.post(`temp/profile-user-save`, data)
            .then((res) => {
                console.log("Save profile response", res.data.data);
                SuccessAlert(res.data.message)
                setProfile({ ...profile, doc_name: '', doc_url: null })

                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }

                getCountries();
                getTitles();
                allStates();
                getUser()
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
                //  ErrorAlert(err.response.data.message)
                console.log(err)
            })
    }


    const handleUpdate = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append('title_id', profile.title_id);
        data.append('user_id', params.user_id);
        data.append('profile_id', profile.profile_id);
        data.append('surname', profile.surname);
        data.append('first_name', profile.first_name);
        data.append('middle_name', profile.middle_name);
        data.append('gender', profile.gender);
        data.append('country_id', profile.country_id);
        data.append('state_id', profile.state_id);
        data.append('lga_id', profile.lga_id);
        data.append('contact_address', profile.contact_address);
        data.append('place_of_birth', profile.place_of_birth);
        data.append('date_of_birth', profile.date_of_birth);
        data.append('permanent_home_address', profile.permanent_home_address);
        data.append('university_attended', profile.university_attended);
        data.append('year_of_call_to_bar', profile.year_of_call_to_bar);
        data.append('law_sch_camp_attended', profile.law_sch_camp_attended);
        // data.append('year_of_admission_law_sch', profile.year_of_admission_law_sch);
        data.append('email', profile.email);
        data.append('phone', profile.phone);
        data.append('temp_profile_id', profile.temp_profile_id);
        data.append('enrollment_id', profile.enrollment_id);
        data.append('doc_name', profile.doc_name);
        data.append('doc_url', profile.doc_url);
        data.append('alternative_name', profile.alternative_name);
        setLoading(true)
        await endpoint.put(`profile/edit`, data)
            .then((res) => {
                console.log("updated profile response", res.data.data);
                setProfile({ ...profile, doc_name: '', doc_url: null })

                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                SuccessAlert(res.data.message)

                getCountries();
                getTitles();
                allStates();
                getUser()
                setLoading(false)
            }).catch((err) => {
                setLoading(false)
                //  ErrorAlert(err.response.data.message)
                console.log(err)
            })

    }

    return (
        <div>
            <div className="page-header" style={{ background: "none" }}>
                <div>
                    <h1 className="page-title" style={{ color: '#0A7E51' }}>Change Of Credence</h1>
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item active" href="#">
                            Home
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            className="breadcrumb-item active breadcrumds"
                            aria-current="page"
                        >
                            {user.user.fullname}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                {user.user.role_id === 2 && (
                    <div className="ms-auto pageheader-btn">
                        <Link
                            to="/my-profile"
                            className="btn btn- btn-icon text- me-3"
                            style={{ color: 'white', backgroundColor: '#007bff' }}
                        >
                            <span>
                                <i className="fe fe-user"></i>&nbsp;
                            </span>
                            View Profile
                        </Link>

                    </div>
                )}
            </div>

            <Row>
                <Col xl={12} lg={12} md={12}>
                    <Card>
                        <Card.Header>
                            <h3 style={{ color: '#0A7E51' }} className="card-title">Personal Details</h3>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Title <span className="text-red">*</span>
                                        </Form.Label>
                                        <select className="form-select" style={{ width: '100%', marginTop: '0px' }}
                                            onChange={(e) => {
                                                setProfile({ ...profile, title_id: e.target.value })
                                            }}
                                            value={profile.title_id ?? ""}
                                        >
                                            <option value="">---Select Title---</option>
                                            {titleList.map(title => (<option key={title.id} value={title.id}>{title.name}</option>))}
                                        </select>

                                    </Form.Group>
                                </Col>

                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Surname <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="text" className="form-control"
                                            value={profile.surname ?? ""}
                                            onChange={(e) => setProfile({ ...profile, surname: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Middle Name <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="text" className="form-control"
                                            value={profile.first_name ? profile.first_name : ""}
                                            onChange={(e) => setProfile({ ...profile, first_name: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Middle Name <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="text" className="form-control"
                                            value={profile.middle_name}
                                            onChange={(e) => setProfile({ ...profile, middle_name: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Gender <span className="text-red">*</span>
                                        </Form.Label>
                                        <select className="form-select" style={{ width: '100%', marginTop: '0px' }}
                                            onChange={(e) => {
                                                setProfile({ ...profile, gender: e.target.value })
                                            }}
                                            value={profile.gender}
                                        >
                                            <option value="">---Select Gender--- </option>
                                            {gender.map(gend => (<option key={gend.id} value={gend.value}>{gend.label}</option>))}
                                        </select>
                                    </Form.Group>
                                </Col>

                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Date of Birth <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="date" className="form-control"
                                            value={profile.date_of_birth}
                                            onChange={(e) => setProfile({ ...profile, date_of_birth: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xl={12} lg={12} md={12}>
                    <Card>
                        <Card.Header>
                            <h3 style={{ color: '#0A7E51' }} className="card-title">Contact Information</h3>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Email <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="email" className="form-control"
                                            value={profile.email}
                                            onChange={(e) => setProfile({ ...profile, email: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Phone Number <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="phone" className="form-control"
                                            value={profile.phone}
                                            onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Contact Address <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="text" className="form-control"
                                            value={profile.contact_address}
                                            onChange={(e) => setProfile({ ...profile, contact_address: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Permanent Address <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="text" className="form-control"
                                            value={profile.permanent_home_address}
                                            onChange={(e) => setProfile({ ...profile, permanent_home_address: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xl={12} lg={12} md={12}>
                    <Card>
                        <Card.Header>
                            <h3 style={{ color: '#0A7E51' }} className="card-title">Location Details</h3>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Country <span className="text-red">*</span>
                                        </Form.Label>
                                        <select className="form-select" style={{ width: '100%', marginTop: '0px' }}
                                            onChange={(e) => {
                                                setProfile({ ...profile, country_id: e.target.value })
                                            }}
                                            value={profile.country_id}
                                        >
                                            <option value="">---Select Country---</option>
                                            {countryList.map(country => (<option key={country.id} value={country.id}>{country.country}</option>))}
                                        </select>
                                    </Form.Group>
                                </Col>
                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            State <span className="text-red">*</span>
                                        </Form.Label>
                                        <select className="form-select" style={{ width: '100%', marginTop: '0px' }}
                                            onChange={(e) => {
                                                handleChange(e.target.value)
                                                setProfile({ ...profile, state_id: e.target.value })
                                            }}
                                            value={profile.state_id}
                                        >
                                            <option value="">---Select State--</option>
                                            {statesList.map(state => (<option key={state.id} value={state.id} selected={profile.state_id === state.id}>{state.state}</option>))}
                                        </select>
                                    </Form.Group>
                                </Col>

                                {profile.Lga && (
                                    < Col sm={6} md={6}>
                                        <Form.Group>
                                            <Form.Label>
                                                LGA <span className="text-red">*</span>
                                            </Form.Label>
                                            <select className="form-select" style={{ width: '100%', marginTop: '0px' }}
                                                onChange={(e) => {
                                                    setProfile({ ...profile, lga_id: e.target.value })
                                                }}
                                                value={profile.lga_id}
                                            >
                                                <option value="">---Select Lga---</option>
                                                {lgasList.length > 0 ? lgasList.map(lga => (<option key={lga.id} value={lga.id} selected={profile.lga_id === lga.id}>{lga.lga}</option>)) : ""}
                                            </select>
                                        </Form.Group>
                                    </Col>
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xl={12} lg={12} md={12}>
                    <Card>
                        <Card.Header>
                            <h3 style={{ color: '#0A7E51' }} className="card-title">Educational Background</h3>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            University Attended <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="text" className="form-control" disabled
                                            value={profile.university_attended}
                                            onChange={(e) => setProfile({ ...profile, university_attended: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Law School Campus Attended <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="text" className="form-control" disabled
                                            value={profile.law_sch_camp_attended}
                                            onChange={(e) => setProfile({ ...profile, law_sch_camp_attended: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>

                                {profile.year_of_admission_law_sch && (
                                    <Col sm={6} md={6}>
                                        <Form.Group>
                                            <Form.Label>
                                                Year Of Admission Into Law School <span className="text-red">*</span>
                                            </Form.Label>
                                            <input type="date" className="form-control" disabled
                                                value={profile.year_of_admission_law_sch}
                                                onChange={(e) => setProfile({ ...profile, year_of_admission_law_sch: e.target.value })}
                                            />
                                        </Form.Group>
                                    </Col>
                                )}

                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Year of Call to Bar <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="date" className="form-control" disabled
                                            value={profile.year_of_call_to_bar}
                                            onChange={(e) => setProfile({ ...profile, year_of_call_to_bar: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col xl={12} lg={12} md={12}>
                    <Card>
                        <Card.Header>
                            <h3 style={{ color: '#0A7E51' }} className="card-title">Supporting Documents  <span className="text-red">* (Please, enter the names of the Document(s) you are uploading, seprate with comma)</span></h3>
                        </Card.Header>
                        <Card.Body>
                            <Row>

                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Document Name <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="text" className="form-control"
                                            value={profile.doc_name}
                                            onChange={(e) => setProfile({ ...profile, doc_name: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col sm={6} md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Document <span className="text-red">*</span>
                                        </Form.Label>
                                        <input type="file" name="doc_url" className="form-control"
                                            accept=".doc, .docx, .pdf"
                                            onChange={(event) => handleFileChange(event)}
                                            ref={fileInputRef}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xl={12} lg={12} md={12}>
                    <button className="btn btn-secondary pd-x-25 m-1" style={{ border: 'white' }} onClick={(e) => handleSave(e)}>
                        <span className="fa fa-save"></span> Save & Continue
                    </button>
                    <button className="btn btn-primary pd-x-25" type="submit" style={{ border: 'white' }}>
                        <span className="fa fa-cloud"></span> Submit
                    </button>
                </Col>
            </Row>

            <br />
        </div >
    );
};

export default ChangeOfName;
