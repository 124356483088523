import React from "react";
import "./styles.css"; // Make sure to import the CSS file

export default function ComingSoon() {
  return (
    <div
      className="card"
      style={{ marginTop: "200px", marginBottom: "200px" }}>
      <h2 className="text-center flash">Coming soon</h2>
    </div>
  );
}
