import React from "react";
import { useState, useContext, useEffect, useRef } from "react";
import endpoint from "../../context/endpoint";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ErrorAlert, SuccessAlert } from "../../data/Toast/toast";
import Loader from "../../data/Loader/loader";


const CallToBarUpload = () => {

  const [loading, setLoading] = useState(false);
  const [cert, setCert] = useState({
    call_to_bar: ''
  })
  const [existingCert, setExistingCert] = useState('')
  const { id } = useParams()

  useEffect(() => {
    getUser()
  }, [])

  const getUser = async () => {
    setLoading(true)
    await endpoint.get(`/enrollment/show/graduand/${id}`)
      .then(({data}) => {
        setLoading(false)
        // console.log("cert", data.data.call_to_bar_cert_dir)
        setExistingCert(data.data.call_to_bar_cert_dir)
      }).catch((err) => {
        console.log(err)
        setLoading(false)
      })

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData()
    data.append('call_to_bar_cert_dir', cert.call_to_bar)
    setLoading(true)
    await endpoint.post(`/enrollment/upload-call-to-bar/${id}`, data)
      .then((res) => {
        console.log(res)
        setLoading(false)
        getUser()
        SuccessAlert(res.data.message)
      }).catch((err) => {
        console.log(err)
        setLoading(false)
        ErrorAlert(err.response.data.message)
      })
  }

  return (
    <>

      <div>
        <div
          className="container-fluid page-header py-5 mb-5 wow fadeIn"
          data-wow-delay="0.1s"
        >
          <div className="container text-center py-5">
            <h1 className="display-3 text-white mb-4 animated slideInDown">
              Call To Bar Certificate
            </h1>

          </div>
        </div>


        <div className="container-fluid py-5">
          <div className="container">
            <div
              className="text-center mx-auto wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                maxWidth: "500px",
                visibility: "visible",
                animationDelay: "0.1s",
                animationName: "fadeInUp",
              }}
            >
              <p className="fs-5 fw-bold text-primary">Please attach your call to bar Certificate below</p>
              <h1 className="display-5 mb-5">Certificate</h1>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                {loading && <Loader />}
                {!loading && <>
                  <form className="form-horizontal" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div
                      className="bg-light rounded p-4 p-sm-5 wow fadeInUp"
                      data-wow-delay="0.1s"
                      style={{
                        visibility: "visible",
                        animationDdelay: "0.1s",
                        animationName: "fadeInUp",
                      }}
                    >

                      <div className="row">
                        <div className="col-md-12">
                          <label>Call to Bar Certificate</label>
                          <input type="file"
                            className="form-control"
                            required
                            onChange={(e) => {
                              setCert({ ...cert, call_to_bar: e.target.files[0] })
                            }}
                          />
                        </div>

                      </div>

                      <div className="row mt-2">
                        <div className="pt-2">
                          <button className="btn btn-primary btn-sm m-1" type="submit"><span className="fa fa-upload"></span> Upload</button>
                          {existingCert &&
                          <a href={`${process.env.REACT_APP_UPLOAD_URL+existingCert}`}
                            target="_blank" className="btn btn-sm btn-success m-1">
                            <span className="fa fa-eye"></span> View Uploaded Certificate
                          </a>
                          }
                        </div>
                      </div>

                    </div>
                  </form>

                </>}
              </div>
            </div>
          </div>
        </div>

      </div>

    </>

  )
}

export default CallToBarUpload