import React, {useEffect} from 'react'
import { Carousel, Breadcrumb, Row, Card, Col } from "react-bootstrap";
import Img1 from './img/scnhomepage.jpg';
import Img2 from './img/scnhomepage.jpg';
import About1 from './img/countBackground2.jpeg';

const Home = () => {

    useEffect(() => {
        const loadWhatsAppWidget = () => {
          const options = {
            whatsapp: "+2348147122184", // WhatsApp number
            call_to_action: "Chat us now", // Call to action
            position: "left", // Position may be 'right' or 'left'
            pre_filled_message: `Chat us now`, // WhatsApp pre-filled message
          };

          const proto = document.location.protocol;
          const host = "getbutton.io";
          const url = proto + "//static." + host;

          const script = document.createElement("script");
          script.type = "text/javascript";
          script.async = true;
          script.src = url + "/widget-send-button/js/init.js";

          script.onload = () => {
            window.WhWidgetSendButton.init(host, proto, options);
          };

          document.body.appendChild(script);
        };

        const loadTawkToScript = () => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://embed.tawk.to/65de8a8a8d261e1b5f663929/1hnmkqbr5';
            script.charset = 'UTF-8';
            script.setAttribute('crossorigin', '*');
      
            document.body.appendChild(script);
          };
          
        loadTawkToScript();
        loadWhatsAppWidget();
    
        // Cleanup function
        return () => {
          // You may want to remove the script when the component unmounts
          // Example: document.body.removeChild(script);
        };
      }, []);

    return (
        <>

            <div className="container-fluid p-0 wow fadeIn" data-wow-delay="0.1s">
                <div id="" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="w-100" src={Img1} alt="Image" style={{width:`100%` }}/>
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-8">
                                            <h5 className="display-4 text-white mb-5 animated slideInDown">Lawyers Enrollment and Credence Application</h5>
                                            <a href={`${process.env.PUBLIC_URL}/enrollment-search`} className="btn btn-primary py-sm-3 px-sm-4">Proceed to Enroll <span className="fa fa-forward"></span> </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100" src={Img2} alt="Image" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-7">
                                            <h1 className="display-1 text-white mb-5 animated slideInDown">Create Your Own Small Garden At Home</h1>
                                            <a href="#" className="btn btn-primary py-sm-3 px-sm-4">Explore More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            <div className="container-fluid top-feature py-5 pt-lg-0">
                <div className="container py-5 pt-lg-0">
                    <div className="row gx-0">
                        <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                            <div className="bg-white shadow d-flex align-items-center h-100 px-5" style={{ minHeight: 160 }}>
                                <div className="d-flex">
                                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-light">
                                        <i className="fa fa-list text-primary" />
                                    </div>
                                    <div className="ps-3">
                                        <h4>Enrollment</h4>
                                        <span>Click on the enroll button, you will be redirected to the Enrollment page.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                            <div className="bg-white shadow d-flex align-items-center h-100 px-5" style={{ minHeight: 160 }}>
                                <div className="d-flex">
                                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-light">
                                        <i className="fa fa-credit-card text-primary" />
                                    </div>
                                    <div className="ps-3">
                                        <h4>Make Payment</h4>
                                        <span>Enter the required fields, search for your details and click on pay.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                            <div className="bg-white shadow d-flex align-items-center h-100 px-5" style={{ minHeight: 160 }}>
                                <div className="d-flex">
                                    <div className="flex-shrink-0 btn-lg-square rounded-circle bg-light">
                                        <i className="fa fa-edit text-primary" />
                                    </div>
                                    <div className="ps-3">
                                        <h4>Enrollment Form</h4>
                                        <span>After Payment have been processed, the enrollment link will be sent to your email. </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-end">
                        
                        <div className="col-lg-6 col-md-7 wow fadeInUp" data-wow-delay="0.3s">
                            {/* <h1 className="display-1 text-primary mb-0">1k +</h1> */}
                            <p className="text-primary mb-4">Legal practitioners</p>
                            <h1 className="display-5 mb-4">Efficient Enrollment Process</h1>
                            <p className="mb-4">We have simplified the enrollment process for legal practioners. through this application, you will be able to search 
                            your name for eligibility, make payment, enter your enrollment details and wait for verification.</p>
                            <a className="btn btn-primary py-3 px-4" href="/enrollment-search">Enroll Now</a>
                        </div>
                        <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="row g-5">
                                <div className="col-12 col-sm-6 col-lg-12">
                                    <div className="border-start ps-4">
                                        <i className="fa fa-check fa-3x text-primary mb-3" />
                                        <h4 className="mb-3">Easy Process</h4>
                                        <span>We have made enrollment process simple and easy, you can apply for enrollment from the comfort of your home</span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-12">
                                    <div className="border-start ps-4">
                                        <i className="fa fa-users fa-3x text-primary mb-3" />
                                        <h4 className="mb-3">Dedicated Team</h4>
                                        <span>We have dedicated IT members that are available 24/7 to guide you through the process</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid facts my-5 py-5" style={{backgroundImage:`url(${About1})`, backgroundRepeat: 'no-repeat', backgroundSize:`100%`, height:`300px`, opacity:`0.8`}}>
                <div className="container py-5">
                    {/* <div className="row g-5">
                        
                        <div className="col-sm-6 col-lg-6 text-center wow fadeIn pull-right" data-wow-delay="0.3s">
                            <h1 className="display-4 text-white" data-toggle="counter-up">1234</h1>
                            <span className="fs-5 fw-semi-bold text-light">Enrolled</span>
                        </div>
                        <div className="col-sm-6 col-lg-6 text-center wow fadeIn pull-left" data-wow-delay="0.5s">
                            <h1 className="display-4 text-white" data-toggle="counter-up">1234</h1>
                            <span className="fs-5 fw-semi-bold text-light">Pending verification</span>
                        </div>
                        
                    </div> */}
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <p className="fs-5 fw-bold text-primary">Why Enroll Online!</p>
                            <h1 className="display-5 mb-4">Few Reasons Why People Enroll online!</h1>
                            <p className="mb-4">The process of enrollment is simple, Easy to use and the process is streamlined, Online enrollment also saves time and reduce cost for the individual.</p>
                            <a className="btn btn-primary py-3 px-4" href="/enrollment-search">Enroll Now</a>
                        </div>
                        <div className="col-lg-6">
                            <div className="row g-4 align-items-center">
                                <div className="col-md-6">
                                    <div className="row g-4">
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                                            <div className="text-center rounded py-5 px-4" style={{ boxShadow: '0 0 45px rgba(0,0,0,.08)' }}>
                                                <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{ width: 90, height: 90 }}>
                                                    <i className="fa fa-check fa-3x text-primary" />
                                                </div>
                                                <h4 className="mb-0">99.9% Satisfactory</h4>
                                            </div>
                                        </div>
                                        <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                                            <div className="text-center rounded py-5 px-4" style={{ boxShadow: '0 0 45px rgba(0,0,0,.08)' }}>
                                                <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{ width: 90, height: 90 }}>
                                                    <i className="fa fa-users fa-3x text-primary" />
                                                </div>
                                                <h4 className="mb-0">Dedicated Team</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 wow fadeIn" data-wow-delay="0.7s">
                                    <div className="text-center rounded py-5 px-4" style={{ boxShadow: '0 0 45px rgba(0,0,0,.08)' }}>
                                        <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{ width: 90, height: 90 }}>
                                            <i className="fa fa-gear fa-3x text-primary" />
                                        </div>
                                        <h4 className="mb-0">Modern Aid</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Home